@import "theme.scss";

/*GRID RESPONSIVE CHANGES*/
main {
  padding-left: 28px;
  padding-right: 28px;
}

table {
  tr {
    border-bottom: none !important;
    td {
      font-size: 18px;
      color: $primary-text-color;
      padding: 24px 24px 24px 0px !important;
      &:first-child {
        padding-left: 24px !important;
      }
    }
  }
  tr > th {
    color: $table-head-text-color !important;
    background-color: $table-head-bg-color !important;
    text-transform: uppercase;
    font-weight: normal !important;
    border: 0px;
    line-height: 22px;
    border-bottom: 3px solid $jnj-blue !important;
    border-top: 3px solid $primary-border-color !important;
    font-size: 18px;
    height: 72px;
    vertical-align: top;
    padding: 24px 24px 24px 0px !important;
    .sort-wrapper {
      position: relative;
      float: left;
    }
    &:first-child {
      padding-left: 24px !important;
    }
    &.p-sortable-column {
      .p-sortable-column-icon {
        color: $text-grey !important;
        position: relative;
        top: 17px !important;
        margin-left: -5px !important;
      }
      &.p-highlight {
        color: $jnj-red !important;

        .p-sortable-column-icon {
          color: $jnj-red !important;
        }
      }
      .sort-wrapper {
        .p-sortable-column-icon {
          position: relative;
          top: -2px !important;
          right: -10px;
        }
      }
    }
  }
  tr:nth-child(odd) {
    td {
      background-color: rgba(92, 151, 191, 0.1) !important;
    }
  }
}

table.table-nostrip tr:nth-child(odd) td {
  background-color: #fff !important;
}
.table-nostrip table tr:nth-child(odd) td {
  background-color: #fff !important;
}
/* Custom breakpoint display modes*/
.desktop-mode {
  display: block;
}
.tablet-mode {
  display: none;
}
.back_next_wrapper {
  position: absolute;
  left: 0px;
  z-index: 999;
  right: 0px;
  background-color: #ffffff;
  padding-left: 72px;
  padding-right: 72px;
}
.common-dialog {
  width: 61.2vw !important;
  &.confirmation-dialog {
    width: 576px !important;
    .p-dialog-footer {
      .button-primary-large {
        width: 150px !important;
        height: 48px;
      }
    }
  }
  .pi.pi-times:before {
    font-family: $icon-font;
    content: "close";
    font-size: 32px;
    color: $text-grey;
  }
  .p-dialog-footer {
    padding-top: 0px !important;
    padding-left: 48px;
    padding-right: 48px;
    padding-bottom: 48px;
  }
}

.mobile-list-item {
  display: none;
}
body .p-component-overlay {
  background-color: rgba(33, 33, 33, 0.8);
}
@media screen and (max-width: $desktop-hd-mode) {
  .back_next_wrapper {
    padding-left: 62px;
    padding-right: 62px;
  }
  .common-dialog {
    width: 91vw !important;
    &.confirmation-dialog {
      width: 576px !important;
      .p-dialog-footer {
        .button-primary-large {
          width: 134px !important;
          height: 48px;
        }
      }
    }
  }
}
@media screen and (max-width: $desktop-mode) {
  .modal-open-units {
    overflow: hidden;
  }
  .date-block {
    max-width: 85px;
    display: inline-block;
  }
  .common-dialog {
    left: 0px !important;
    opacity: 1;
    right: 0px;
    width: 100vw !important;
    top: 0px !important;
    bottom: 0px;
    height: 100vh !important;
    &.confirmation-dialog {
      width: 100vw !important;
      .p-dialog-content {
        padding-left: 13.5vw !important;
        padding-right: 13.5vw !important;
        padding-top: 13vh;
      }
      .p-dialog-footer {
        .button-primary-large {
          width: 150px !important;
          height: 48px;
        }
      }
      // .p-dialog-header {
      //   // border-bottom: 0px !important;
      // }
    }
    .p-dialog-header {
      height: 72px;
      border-bottom: 1px solid $primary-border-color !important;
    }
    .p-dialog-content {
      height: calc(100vh - 148px) !important;
      padding-left: 28px !important;
      padding-right: 28px !important;
    }
    .p-dialog-footer {
      padding-top: 0px !important;
      padding-left: 28px;
      padding-right: 28px !important;
      padding-bottom: 28px !important;
    }
  }
  .back_next_wrapper {
    padding-left: 28px;
    padding-right: 28px;
  }
  .desktop-mode {
    display: none;
  }
  .tablet-mode {
    display: block;
  }
  table {
    tr > th,
    tr > td {
      font-size: 14px !important;
      &.f-18 {
        font-size: 14px !important;
      }
    }
    tr > th {
      padding: 24px 10px 10px 0px !important;
      &:nth-child(1) {
        padding-left: 10px !important;
      }
      &.p-sortable-column {
        .sort-wrapper {
          .p-sortable-column-icon {
            top: -4px !important;
            margin-left: -3px !important;
            right: -18px;
          }
        }
      }
    }
    tr > td {
      padding: 24px 10px 24px 0px !important;
      &:nth-child(1) {
        padding-left: 10px !important;
      }
    }
  }
}

@media screen and (max-width: $tablet-mode) {
  .common-dialog {
    .p-dialog-content {
      padding-left: 26px !important;
      padding-right: 26px !important;
    }
    .p-dialog-footer {
      padding-left: 26px;
      padding-right: 26px !important;
      padding-bottom: 26px !important;
    }
    &.confirmation-dialog {
      .p-dialog-content {
        padding-top: 10vh;
      }
    }
  }
  .portrait-content {
    max-width: 88%;
  }
  table {
    tr > th,
    tr > td {
      font-size: 14px !important;
      line-height: 2.865vw;
      &.f-18 {
        font-size: 14px !important;
        line-height: 2.865vw;
      }
    }
  }
  .back_next_wrapper {
    padding-left: 26px;
    padding-right: 26px;
  }
}
@media screen and (max-width: $tablet-portrait-mode) {
  .mobile-list-item {
    display: list-item;
  }
  .hide-in-mobile {
    display: none;
  }
}
/* Custom breakpoint display modes*/
// $mobile-mode-mode - 320px
@media screen and (min-width: #{$mobile-mode + 1}) {
  main {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .header1 {
    font-size: 28px;
    line-height: 36px;
  }
  .header2 {
    font-size: 20px;
    line-height: 36px;
  }
  .header3 {
    font-size: 18px;
    line-height: 24px;
  }
  .header4 {
    font-size: 18px;
    line-height: 24px;
  }
  .page-sub-title {
    font-size: 18px;
    line-height: 24px;
  }
  .instructions-text {
    font-size: 14px;
    line-height: 20px;
  }
}

// $tablet-portrait-mode - 600px
@media screen and (min-width: 600px) {
  main {
    //padding-left:Max(4.667vw , 24px) !important;
    //padding-right:Max(4.667vw , 24px) !important;
    padding-left: 29px !important;
    padding-right: 29px !important;
  }
  .header1 {
    font-size: 48px;
    line-height: 52px;
  }
  .header2 {
    font-size: 28px;
    line-height: 34px;
  }
  .header3 {
    font-size: 22px;
    line-height: 26px;
  }
  .header4 {
    font-size: 16px;
    line-height: 22px;
  }
  .page-sub-title {
    font-size: 18px;
    line-height: 22px;
  }
  .instructions-text {
    font-size: 18px;
    line-height: 24px;
  }
}
// $tablet-mode - 768px
@media screen and (min-width: $tablet-mode) {
  main {
    //padding-left:Max(3.776vw, 24px) !important;
    //padding-right:Max(3.776vw, 24px) !important;
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
}
// $desktop-mode - 1024px
@media screen and (min-width: #{$desktop-mode + 1}) {
  main {
    padding-left: 62px !important;
    padding-right: 62px !important;
  }
  .header1 {
    font-size: 60px;
    line-height: 60px;
  }
  .header2 {
    font-size: 40px;
    line-height: 44px;
  }
  .header3 {
    font-size: 28px;
    line-height: 32px;
  }
  .header4 {
    font-size: 18px;
    line-height: 22px;
  }
  .page-sub-title {
    font-size: 20px;
    line-height: 32px;
  }
  .instructions-text {
    font-size: 16px;
    line-height: 20px;
  }
  // BUTTON DESIGN
  .button-primary-large,
  .button-secondary-large,
  .button-primary-large-blue,
  .button-secondary-large-blue {
    width: 134px;
    height: 48px;
  }
}
@media screen and (min-width: #{$desktop-hd-mode + 1}) {
  main {
    padding-left: 72px !important;
    padding-right: 72px !important;
  }
  .button-primary-large,
  .button-secondary-large,
  .button-primary-large-blue,
  .button-secondary-large-blue {
    width: 150px;
    height: 48px;
  }
}
