@import "theme.scss";
/*
 * Legal Disclaimer 
 
 * These web /assets/font are licensed exclusively for use on the following:
 * Domains belonging to Johnson&Johnson
 *
 * It is strictly forbidden to download or use these /assets/font on any other website domain or media.
 *
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2018
 */

/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The /assets/font folder(s) should be placed relative to the regular CSS file.
 *
 */

@font-face {
  font-family: "J&J Circular Bold Web";
  src: url("/assets/fonts/J&J CircularTT-Bold.eot");
  src: url("/assets/fonts/J&J CircularTT-Bold.woff2") format("woff2"),
    url("/assets/fonts/J&J CircularTT-Bold.woff") format("woff");
  src: url("/assets/fonts/J&J CircularTT-Bold.eot");
  src: url("/assets/fonts/J&J CircularTT-Bold.woff2") format("woff2"),
    url("/assets/fonts/J&J CircularTT-Bold.woff") format("woff");
}

@font-face {
  font-family: "J&J Circular Book Web";
  src: url("/assets/fonts/J&J CircularTT-Book.eot");
  src: url("/assets/fonts/J&J CircularTT-Book.woff2") format("woff2"),
    url("/assets/fonts/J&J CircularTT-Book.woff") format("woff");
}

@font-face {
  font-family: "J&J Circular Light Web";
  src: url("/assets/fonts/J&J CircularTT-Light.eot");
  src: url("/assets/fonts/J&J CircularTT-Light.woff2") format("woff2"),
    url("/assets/fonts/J&J CircularTT-Light.woff") format("woff");
}

@font-face {
  font-family: "J&J Circular Medium Web";
  src: url("/assets/fonts/J&J CircularTT-Medium.eot");
  src: url("/assets/fonts/J&J CircularTT-Medium.woff2") format("woff2"),
    url("/assets/fonts/J&J CircularTT-Medium.woff") format("woff");
}

@font-face {
  font-family: "J&J Circular Black Web";
  src: url("/assets/fonts/J&J CircularTT-Black.eot");
  src: url("/assets/fonts/J&J CircularTT-Black.woff") format("woff"),
    url("/assets/fonts/J&J CircularTT-Black.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Sans Bold";
  src: url("/assets/fonts/Noto Sans Bold.eot");
  src: url("/assets/fonts/Noto Sans Bold.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Noto Sans Bold.woff2") format("woff2"),
    url("/assets/fonts/Noto Sans Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-02DC;
}

@font-face {
  font-family: "Noto Sans Regular";
  src: url("/assets/fonts/Noto Sans Regular.eot");
  src: url("/assets/fonts/Noto Sans Regular.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Noto Sans Regular.woff2") format("woff2"),
    url("/assets/fonts/Noto Sans Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-02DC;
}

@font-face {
  font-family: "Noto Sans Black";
  src: url("/assets/fonts/Noto Sans Black.eot");
  src: url("/assets/fonts/Noto Sans Black.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Noto Sans Black.woff2") format("woff2"),
    url("/assets/fonts/Noto Sans Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-02DC;
}

@font-face {
  font-family: "Noto Sans SemiBold";
  src: url("/assets/fonts/Noto Sans SemiBold.eot");
  src: url("/assets/fonts/Noto Sans SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Noto Sans SemiBold.woff2") format("woff2"),
    url("/assets/fonts/Noto Sans SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-02DC;
}

@font-face {
  font-family: "Noto Sans Med";
  src: url("/assets/fonts/Noto Sans Medium.eot");
  src: url("/assets/fonts/Noto Sans Medium.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Noto Sans Medium.woff") format("woff2"),
    url("/assets/fonts/Noto Sans Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-02DC;
}

body {
  margin: 0;
  height: 100%;
  background: $primary-body-color;
  color: $primary-text-color;
  font-family: $primary-font-family-regular;
  font-size: $primary-font-size;
}

body ul li {
  padding: 0;
  margin: 0;
}

*:focus {
  outline: none;
  box-shadow: none !important;
}

a {
  cursor: pointer;
  color: inherit;
  text-decoration: none !important;
}

/* Font styles start */

.book {
  font-family: $primary-font-family-book;
}

.bold {
  font-family: $primary-font-family-bold !important;
}

.semibold {
  font-family: $primary-font-family-semibold;
}

.light {
  font-family: $primary-font-family-light;
}

.regular {
  font-family: $primary-font-family-regular;
}

.medium {
  font-family: $primary-font-family-medium;
}

.black {
  font-family: $primary-font-family-black;
}

.f-12 {
  font-size: 12px;
}

.f-13 {
  font-size: 13px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px !important;
}

.f-20 {
  font-size: 20px;
}

.f-28 {
  font-size: 28px;
}

.f-40 {
  font-size: 40px !important;
}

.f-60 {
  font-size: 60px;
}

.blue {
  color: $jnj-blue !important;
}

/* Font styles end */

// common Button
.cancel-button {
  color: $primary-text-color;
}

button {
  &:focus {
    outline: none;
  }
}

// common Button

/* Button Styles start */

.button-primary-large,
.button-primary-medium,
.button-primary-small {
  background-color: $jnj-red;
  border: none;
  color: $text-white;
  border-radius: 0px;
  font-family: $primary-font-family-semibold;
  padding: 0 6px;
  font-size: 14px;
}

.button-primary-large-blue {
  background-color: $jnj-blue;
  border: none;
  color: $text-white;
  border-radius: 0px;
  font-family: $primary-font-family-semibold;
  padding: 0 6px;
  font-size: 14px;
}

.button-primary-large:active,
.button-primary-medium:active,
.button-primary-small:active,
.button-primary-large-blue:active {
  background-color: $primary-text-color;
}

.button-secondary-large,
.button-secondary-medium,
.button-secondary-small {
  background-color: #fff;
  border: solid 2px $jnj-red;
  color: $jnj-red;
  border-radius: 0px;
  font-family: $primary-font-family-semibold;
  padding: 0 6px;
  font-size: 14px;
}

.button-secondary-large-blue {
  background-color: #fff;
  border: solid 2px $jnj-blue;
  color: $jnj-blue;
  border-radius: 0px;
  font-family: $primary-font-family-semibold;
  padding: 0 6px;
  font-size: 14px;
}

a.button-primary-large {
  line-height: 45px;
  color: #fff !important;
}

a.button-primary-medium,
a.button-primary-small {
  line-height: 38px;
  color: #fff !important;
}

.button-primary-large,
.button-secondary-large,
.button-primary-large-blue,
.button-secondary-large-blue {
  width: 150px;
  height: 48px;
}

.button-primary-medium,
.button-secondary-medium {
  width: 134px;
  height: 48px;
}

.button-primary-small,
.button-secondary-small {
  width: 110px;
  height: 36px;
}

.button-primary-large:hover:enabled,
.button-primary-medium:hover:enabled,
.button-primary-small:hover:enabled,
a.button-primary-large:hover,
a.button-primary-medium:hover,
a.button-primary-small:hover {
  border: solid 2px $jnj-red;
  background: $text-white;
  color: $jnj-red !important;
  font-size: 14px;
}

.button-primary-large-blue:hover:enabled,
a.button-primary-large-blue:hover {
  border: solid 2px $jnj-blue;
  background: $text-white;
  color: $jnj-blue !important;
  font-size: 14px;
}

.button-secondary-large:hover:enabled,
.button-secondary-medium:hover:enabled,
.button-secondary-small:hover:enabled {
  background-color: $jnj-red;
  border: none;
  color: $text-white;
  font-size: 14px;
}

.button-secondary-large-blue:hover:enabled {
  background-color: $jnj-blue;
  border: none;
  color: $text-white;
  font-size: 14px;
}

.btn:disabled {
  background-color: $light-grey;
  border: none;
  color: $disabled-text;
}

.button-primary-large:not(:disabled):not(.disabled).active,
.button-primary-large:not(:disabled):not(.disabled):active,
.show > .button-primary-large.dropdown-toggle {
  color: #d61200;
  background-color: #fff;
  border-color: #e1685e;
}

/* Button Styles end */

/* Pretty checkbox styles start */

.pretty label {
  cursor: pointer;
}

.pretty.p-default input:checked ~ .state label:after {
  border: solid 2px $jnj-red !important;
  background-color: $jnj-red !important;
}

.recall-mobile,
.expire-mobile {
  .pretty.p-default input:checked ~ .state label:after {
    border: solid 2px $jnj-blue !important;
    background-color: $jnj-blue !important;
  }
}

.pretty.p-default input:disabled ~ .state label:after {
  border: solid 2px #d8d8d8 !important;
  background-color: #d8d8d8 !important;
}

.pretty.p-default input:checked:disabled ~ .state label:after {
  border: solid 2px $jnj-red !important;
  background-color: $jnj-red !important;
  opacity: 0.4;
}

.pretty .state label:before,
.pretty .state label:after {
  border: solid 1px #d8d8d8 !important;
  width: calc(1.3em + 2px);
  height: calc(1.3em + 2px);
}

.pretty.radio.p-default .state label:after {
  transform: scale(1);
}

/* Pretty checkbox styles end */

/* Material styles start */
.mat-mdc-text-field-wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;
  //   background-color: transparent !important;
  //   border: 1px solid $secondary-text-color !important;
  //   padding: 0 0 0 0 !important;
  //   border-radius: 10px;
  //   box-shadow: 0px 2px 5px 0px $secondary-text-color;
}

.mat-mdc-form-field-required-marker.mdc-floating-label--required {
  visibility: hidden !important;
}

.mat-mdc-form-field:not(.mat-form-field-disabled)
  .mat-mdc-floating-label.mdc-floating-label {
  color: $primary-text-color !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
  padding: 0;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled):hover {
  background-color: transparent !important;
  padding: 0;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mdc-text-field--focused:hover {
  background-color: transparent !important;
  padding: 0;
}

.mdc-text-field--filled:hover {
  background-color: transparent !important;
  padding: 0;
}

.mdc-floating-label {
  color: $primary-text-color;
  font-size: 18px;
  line-height: 13px;
}

.mdc-text-field--filled:hover {
  background-color: transparent !important;
}

.mat-form-field-appearence-fill .mat-form-field-label {
  color: $primary-text-color;
}

.mat-form-field-appearence-fill .mat-mdc-form-field-infix {
  padding: 0.4775em 0;
}

.profile .mat-form-field-appearence-fill .mat-mdc-form-field-infix {
  padding: 0.4775em 0;
}

.profile .mat-mdc-form-field .mat-mdc-form-field-infix {
  font-size: 16px !important;
  padding-bottom: 15px;
  line-height: 18px !important;
}

.profile .mat-mdc-form-field.firstname1 .mat-form-field-infix,
.profile .mat-mdc-form-field.lastname1 .mat-mdc-form-field-infix {
  padding-bottom: 18px !important;
}

.profile .mat-form-field-invalid .mat-mdc-form-field-infix {
  border-bottom: 3px solid $jnj-red !important;
  font-size: 14px !important;
}

// .profile .create-new-pswd.mat-form-field-invalid .mat-mdc-form-field-infix {
// padding-top: 32px;
// }
.profile .cnfirm-pswd.mat-form-field-invalid .mat-mdc-form-field-infix {
  margin-top: 18px;
}

/* .mat-form-field-appearence-fill .mat-form-field-invalid .ng-invalid.mat-form-field-label {
    background-color: $jnj-red !important;
} */

.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-label {
  // color: $jnj-red !important;
}

.mat-mdc-form-field.mat-focused .mat-form-field-label {
  color: $primary-text-color;
}

.mat-mdc-form-field.mat-form-field-disabled .mat-form-field-label {
  color: $disabled-text;
}

.date-picker-container {
  .mat-form-field-disabled {
    cursor: no-drop !important;
    .mat-mdc-input-element,
    .mat-datepicker-toggle-default-icon {
      cursor: no-drop !important;
    }
  }
}

.mdc-line-ripple {
  background-color: $primary-text-color !important;
  border-radius: 0px !important;
}

.mdc-line-ripple .mat-mdc-form-field-invalid .ng-invalid {
  background-color: $jnj-red !important;
  border-radius: 0px !important;
}

.mat-mdc-form-field-infix {
  border-bottom: 2px solid $input-enabled-border;
  font-family: $primary-font-family-regular;
  font-size: 18px;
  color: $primary-text-color;
  line-height: 20px;
}

.sutUtil .mat-mdc-form-field-infix {
  border-bottom: 2px solid $input-enabled-border;
  font-family: $primary-font-family-regular;
  font-size: 18px;
  color: $primary-text-color;
  line-height: 20px;
}

.sutUtil .mat-form-field-disabled .mat-mdc-form-field-infix {
  border-bottom: 2px solid $text-grey;
}

.mat-elevation-z4 {
  box-shadow: none !important;
}

.mat-tab-body-wrapper {
  padding: 20px !important;
}

.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: $jnj-red !important;
}

.mat-form-field-invalid .mat-mdc-form-field-infix {
  // border-bottom: 3px solid $jnj-red !important;
}

:host ::ng-deep .mdc-line-ripple::before {
  border-bottom-color: $input-enabled-border !important;
}

:host
  ::ng-deep
  .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-line-ripple::after {
  border-bottom-color: $input-enabled-border !important;
}

.mat-form-field-invalid .mdc-line-ripple {
  background-color: $jnj-red !important;
}

.mat-mdc-input-element,
.mat-mdc-form-field {
  font-family: $primary-font-family-regular !important;
  font-size: 18px !important;
}

.mat-form-field-invalid .mat-mdc-input-element {
  caret-color: $jnj-red !important;
  color: $jnj-red !important;
}

.mat-warn .mat-mdc-input-element {
  caret-color: $jnj-red !important;
  color: $jnj-red !important;
}

.profile .mat-form-field-invalid .mat-mdc-input-element,
.mat-warn .mat-mdc-input-element {
  caret-color: $jnj-red !important;
  color: $jnj-red !important;
  font-family: $primary-font-family-regular !important;
  font-size: 16px !important;
}

.productEdit .mat-mdc-input-element,
.mat-mdc-form-field {
  font-family: $primary-font-family-regular !important;
  font-size: 19px !important;
}

.reset-pwd .mat-form-field-appearence-fill .mat-mdc-form-field-infix {
  padding: 0.6em 0 !important;
}

.reset-pwd
  .tick_padding
  .mat-form-field-appearence-fill
  .mat-mdc-form-field-infix {
  padding-top: 0.25em !important;
  padding-bottom: 0.6em !important;
}

.login .mat-mdc-form-field .mat-mdc-form-field-infix {
  line-height: 13px !important;
}

.req-access .mat-mdc-form-field .mat-mdc-form-field-infix {
  line-height: 13px !important;
}

.reset-pwd .mat-mdc-form-field .mat-mdc-form-field-infix {
  line-height: 18px !important;
}

.forgot-pwd .mat-mdc-form-field .mat-mdc-form-field-infix {
  line-height: 13px !important;
}

/* Material styles end */

/*Dropdown styles start*/

body .productEdit .p-dropdown .p-dropdown-label {
  font-size: 18px !important;
  margin-left: 0px !important;
  padding-top: 0px;
  padding-bottom: 6px;
  padding-left: 0px;
}

body
  .productEdit
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  border-right: 1px solid $light-grey !important;
}

body
  .productEdit
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item.p-highlight {
  border-right: 1px solid $light-grey !important;
}

body .productEdit .p-dropdown .p-dropdown-label {
  font-size: 18px !important;
  margin-left: 0px !important;
  padding-top: 0px;
  padding-bottom: 7px;
  padding-left: 0px;
}

.productEdit body .p-inputtext {
  font-size: 18px !important;
}

body .sutUtil .p-dropdown .p-dropdown-label {
  font-size: 18px !important;
  margin-left: 0px !important;
  padding-top: 0px;
  padding-bottom: 2px;
  padding-left: 0px;
  color: $primary-text-color !important;
}

body .req-access .p-inputtext.p-placeholder.p-dropdown-label {
  margin-left: -6px !important;
  font-family: $primary-font-family-regular;
  font-size: 18px;
  line-height: 20px;
  color: #212121;
  padding-top: 0.76em !important;
  padding-bottom: 0.76em !important;
  padding-left: 5px !important;
}

body .req-access .p-dropdown label.p-dropdown-label {
  cursor: pointer;
  margin-left: -0.8% !important;
}

.custom-p-dropdown.dropdown-style.ng-invalid .p-dropdown {
  border-bottom: 3px solid $jnj-red;
}

.custom-p-dropdown.dropdown-style.ng-invalid .p-dropdown-label.p-placeholder {
  color: $jnj-red !important;
}

.profile {
  .p-dropdown.p-component,
  .p-inputtext.p-component {
    width: 395px !important;
  }
  .mat-form-field-appearance-legacy.no-label .mat-mdc-form-field-infix {
    margin-top: -23px;
  }
}

body .p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
}

/*P-Dropdown styles end*/

/* Form controls styles start */

.form-control.input {
  font-size: $primary-font-size;
  border: none;
  border-bottom: solid 1px $primary-text-color;
  border-radius: 0px;
  color: $primary-text-color;
  outline: none;
}

.form-control {
  padding: 0.375rem 0.75rem 0.75em 0 !important;
}

.form-control.input:disabled,
.form-control.dropdown:disabled {
  background: #fff;
  color: #888b8d;
  background-image: linear-gradient(
    to right,
    #949494 54%,
    rgba(255, 255, 255, 0) 24%
  );
  background-position: bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x;
  opacity: 0.7;
  border-bottom: none !important;
}

.form-control.input.other-input:disabled {
  background: #fff;
  color: #888b8d;
  display: none;
}

.form-control.dropdown {
  font-size: $primary-font-size;
  border: none;
  border-bottom: 3px solid $input-enabled-border;
  border-radius: 0px;
  color: $primary-text-color;
}

.form-control.checkbox {
  font-size: 0px;
  margin: 3px;
  width: 20px;
  float: left;
}

select {
  border: none;
  border-bottom: solid 1px $input-enabled-border;
}
table {
  caption-side: bottom;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}
body .p-component,
body .p-datatable {
  font-family: $primary-font-family-regular !important;
}

.p-dropdown.p-component,
.p-inputtext.p-component {
  border: none;
  border-bottom: 2px solid $input-enabled-border;
  border-radius: 0;
  width: 100% !important;
  color: $primary-text-color;
}

.p-card-data-table .p-dropdown.p-component,
.p-inputtext.p-component {
  border-radius: 4px;
  width: 100% !important;
  color: $primary-text-color;
}

.p-card-data-table .pi-search {
  position: absolute;
  font-size: 1.6em;
  padding-top: 0.6em;
  color: $disabled-grey;
}

.time-filters {
  .mat-form-field-appearance-fill .mat-mdc-form-field-infix {
    padding: 0 !important;
  }
}

.filter-container {
  .tile-filters {
    p-calendar {
      .p-inputtext.p-component {
        border: none;
        border-bottom: 2px solid $input-enabled-border;
        border-radius: 0;
        width: 30% !important;
        color: $primary-text-color;
      }
    }
    .p-autocomplete {
      width: 100% !important;
    }
    .p-autocomplete-token {
      background-color: $jnj-red !important;
      width: 100% !important;
    }
    .p-autocomplete-token-label {
      margin-right: 0em !important;
      max-width: 92% !important;
      float: left !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    // .p-autocomplete-token-icon {
    // margin-top: 0.15em !important;
    // position: relative !important;
    // right: 0.2em !important;
    // top: 50% !important;
    // float: left !important;
    // }
    .p-radiobutton-box {
      box-shadow: 0px 2px 5px 0px $secondary-text-color;
    }
    .p-checkbox-box {
      box-shadow: 1px 2px 3px 0px $secondary-text-color;
    }
  }
  .p-dialog,
  .customDialog .p-dialog-header,
  .customDialog .p-dialog-content,
  .customDialog .p-dialog-footer.p-dialog-footer {
    border-radius: 20px !important;
    padding: 8px 0px !important;
  }
  .mat-mdc-form-field-infix {
    border: none !important;
  }
  .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex {
    background-color: transparent !important;
    border: 1px solid $secondary-text-color !important;
    padding: 0 0 0 0 !important;
    border-radius: 10px;
    box-shadow: 0px 2px 5px 0px $secondary-text-color !important;
  }
  .mat-mdc-form-field-flex {
    display: contents !important;
    background-color: transparent !important;
    border: 1px solid $secondary-text-color !important;
    padding: 0 0 0 0 !important;
    border-radius: 10px;
    box-shadow: 0px 2px 5px 0px $secondary-text-color;
    width: 200px;
    height: 49px;
  }
  .mat-form-field-appearance-fill .mat-mdc-form-field-infix {
    padding: 0 0.5em;
    line-height: 8px !important;
  }
  .mat-form-field-underline {
    display: none !important;
  }
  .mat-mdc-input-element:disabled,
  .mat-icon-button[disabled][disabled] {
    cursor: no-drop !important;
  }
  .mat-datepicker-toggle-active {
    svg {
      color: $jnj-red;
    }
  }
}

a.p-state-active,
span.p-state-active {
  background-color: $jnj-red !important;
}

body .mat-calendar-body-selected {
  background-color: $jnj-red !important;
}

body .p-dialog-mask.p-component-overlay {
  z-index: 1000 !important;
}

body .p-datepicker {
  z-index: 11005 ​ !important;
}

.p-dropdown.p-component.p-state-default {
  padding-top: 0;
}

// .add-user .p-dropdown.p-component.p-state-default, .list-user .p-dropdown.p-component.p-state-default {
//   padding-top: 10px;
// }
// .p-dropdown > .p-dropdown-label-container > .p-dropdown-label {
// }
.pi {
  font-family: primeicons;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.25em;
}

body .p-dropdown .p-dropdown-label-container {
  width: inherit;
  .p-dropdown-label {
    text-overflow: ellipsis;
    padding-left: 0;
    padding-right: 25px;
  }
}

body .p-dropdown .p-dropdown-label {
  font-size: 18px !important;
  color: $text-grey !important;
  overflow: hidden;
  flex: 1 1 auto;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
}

body .user-tbl .p-dropdown .p-dropdown-label {
  font-size: 18px !important;
}

body .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: transparent;
  color: $primary-text-color;
}

body .p-dropdown-panel .p-dropdown-items .p-dropdown-item,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  color: $text-grey !important;
  position: relative;
  left: 20px;
}

body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon {
  color: #2b2d2f !important;
}

body .p-dialog {
  max-height: 100%;
  background: $primary-body-color;
  background-color: $primary-body-color;
}

.p-dialog-resizable .p-dialog-content,
.p-dialog .p-dialog-content {
  overflow: hidden !important;
}

body .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  border-bottom: 1px solid $light-grey !important;
  font-family: $primary-font-family-medium;
  color: $text-grey;
  text-align: left;
  padding: 0.429em 0.857em;
  margin-left: -31px;
  font-size: 14px;
  
}
body .p-dropdown-panel .p-dropdown-items{
  overflow-x: hidden;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item{
  left: 30px !important;


}

body .p-dropdown-panel .p-dropdown-items .p-dropdown-item:hover {
  font-family: $primary-font-family-black;
  color: $text-grey;
  background-color: $primary-body-color !important;
}

body .p-dropdown-panel .p-dropdown-items-wrapper {
  font-family: $primary-font-family-black;
  color: $text-grey;
  background-color: $primary-body-color !important;
  border: 1px solid $light-grey;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-dropdown .p-dropdown-label {
  height: 30px !important;
  color: $text-grey !important;
  // font-size: 14px !important;
  font-family: $primary-font-family-medium;
  text-align: left;
  padding-left: 6px !important;
}
.p-corner-all {
  border-bottom: 1px lightgray;
}

/* radio button style start **/

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: $jnj-red !important;
  background-color: $jnj-red !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight span {
  border-color: $jnj-red !important;
  background-color: $jnj-red !important;
}

.expire-product-list {
  .p-radiobutton-box.p-state-active,
  .p-radiobutton-box.p-state-active span {
    border-color: $jnj-blue !important;
    background-color: $jnj-blue !important;
  }
}

body .p-dropdown:not(.p-disabled).p-focus {
  border-color: $input-enabled-border !important;
}

body .profile .p-radiobutton .p-radiobutton-box.p-state-active {
  box-shadow: none !important;
}

/* radio button style ends */

/* Form controls styles end */

/* Multi select plugin styles start */

.multiselect-dropdown .dropdown-btn .selected-item {
  border: none !important;
  margin-right: 4px;
  background: none !important;
  color: $primary-text-color !important;
  overflow: hidden;
  float: left;
  white-space: nowrap;
  max-width: 180px;
  font-size: 18px !important;
}

.multiselect-item-checkbox input[type="checkbox"]:checked + div:before {
  background: $jnj-red !important;
}

.multiselect-item-checkbox input[type="checkbox"] + div:before {
  color: $jnj-red !important;
  border: 2px solid $jnj-red !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-down {
  border-top: 5px solid #000000 !important;
  border-left: 4px solid transparent !important;
  border-right: 4px solid transparent !important;
}

.multiselect-dropdown .dropdown-btn {
  border: none !important;
  border-bottom: solid 2px $text-grey !important;
  border-radius: 0 !important;
  padding: 6px 0px 0px 0px !important;
  width: 226px !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-up {
  border-bottom: 5px solid #000000 !important;
  border-left: 4px solid transparent !important;
  border-right: 4px solid transparent !important;
}

.multiselect-dropdown .dropdown-btn .selected-item:hover {
  box-shadow: none !important;
}

.multiselect-dropdown .disabled > span {
  background-color: #fff !important;
  border-bottom: solid 2px $disabled-text !important;
  color: $disabled-text !important;
  span a {
    display: none;
  }
}

.multiselect-dropdown .disabled .dropdown-btn {
  cursor: auto !important;
}

.multiselect-dropdown .disabled .selected-item {
  color: $disabled-text !important;
}

.multiselect-item-checkbox input[type="checkbox"] + div:after {
  border-color: $jnj-red !important;
}

.multi .multiselect-dropdown .disabled .dropdown-btn {
  cursor: pointer !important;
}

.multiselect-item-checkbox input[type="checkbox"] + div:before {
  background-color: $primary-body-color !important;
}

/* .error_multiselect  */

.sutUtil .ng-invalid .multiselect-dropdown .dropdown-btn {
  border-bottom: 3px solid $jnj-red !important;
  color: $jnj-red;
}

.sutUtil .ng-valid .multiselect-dropdown .dropdown-btn {
  border-bottom: 2px solid $text-grey !important;
  color: $text-grey;
}

/* .error_multiselect  */

.multiselect-dropdown .dropdown-btn .selected-item .ng-dirty .ng-invalid {
  color: $jnj-red !important;
}

/* Multi select plugin styles end*/

/* prime-Ng custom tab start*/

.p-tab-custom {
  .p-tabview .p-tabview-nav li {
    background-color: transparent;
    border: none;
    color: $primary-text-color;
    font-family: $primary-font-family-medium;
    text-align: center;
    padding-bottom: 7px;
    margin-right: 43px;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    //need
    background-color: transparent;
    border: none;
    // color: $primary-text-color;
    color: $text-grey;
    font-family: $primary-font-family-medium;
    text-align: center;
    // padding-bottom: 7px;
    // margin-right: 43px;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link:hover {
    //need
    background-color: transparent;
    border: none;
    color: $primary-text-color;
    font-family: $primary-font-family-medium;
    text-align: center;
    // padding-bottom: 7px;
    // margin-right: 43px;
  }
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    //need
    background-color: transparent;
    border: none;
    // border-bottom: 3px solid $jnj-red;
    color: $text-grey !important;
    font-family: $primary-font-family-bold;
    text-align: center;
  }
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link:hover {
    //need
    background-color: transparent;
    border: none;
    // border-bottom: 3px solid $jnj-red;
    color: $primary-text-color !important;
    font-family: $primary-font-family-bold;
    text-align: center;
  }
  .p-tabview .p-tabview-nav li a.p-tabview-nav-link {
    //need
    text-decoration: none !important;
    color: $text-grey;
    padding: 0px;
    font-weight: unset;
    max-width: 300px;
    text-align: left;
  }
}

.p-tab-custom.account-setting-page {
  .p-tabview.p-tabview-nav-container .p-tabview-content .p-tabview-nav li {
    margin-right: 15px;
    min-width: 126px;
    // .p-highlight {
    //     border-bottom: 3px solid $jnj-red;
    // }
  }
}

.p-tab-custom {
  .p-tabview {
    .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav {
      //need
      padding: 0;
      background: transparent;
      border: 0 none;
    }

    .p-tabview-nav-container
      .p-tabview-nav-content
      .p-tabview-nav
      .p-tabview-nav-link
      .p-tabview-title {
      text-transform: uppercase; //need
    }
    .p-tabview-nav-container
      .p-tabview-nav-content
      .p-tabview-nav
      .p-tabview-nav-link
      .p-tabview-title
      a {
      text-decoration: none !important; //need
    }
    .p-tabview-panels {
      background: transparent;
      border: none;
      .p-tabview-panel {
        background: transparent;
      }
    }

    .p-tabview-nav {
      li {
        margin-right: 40px;
        padding-bottom: 12px;
        &.p-highlight {
          border-bottom: 3px solid $jnj-red;
        }
        a {
          max-width: fit-content !important;
          // max-width: 160px;
          color: $text-grey;
          font-size: 18px;
          line-height: 22px;
        }
        &:last-child {
          a {
            width: 76px;
            max-width: 76px;
          }
        }
      }
    }
  }
}

/* prime-Ng custom tab end*/

/* prime overlay panel styles start */

.custom-overlaypanel-pm {
  .p-overlaypanel {
    .p-overlaypanel-content {
      .content-grid {
        .dropdown-column {
          .topics {
            a.dropdown-subheading {
              color: $primary-text-color !important;
              font-family: $primary-font-family-bold;
              text-decoration: none;
            }
            a.custom-item {
              color: $primary-text-color !important;
            }

            a.custom-item:hover {
              color: $jnj-red !important;
              font-family: $primary-font-family-black;
            }
          }
        }
      }
    }
  }
}

.p-overlaypanel:before {
  visibility: hidden;
}

.p-overlaypanel:after {
  visibility: hidden;
}

button.p-overlaypanel-close.p-link {
  position: absolute !important;
  right: 0px !important;
  top: 0px !important;
  color: $primary-text-color;
  background-color: transparent;
}

/* prime overlay panel styles end */

body .p-dropdown-panel {
  z-index: 99999 !important;
}

.pi {
  vertical-align: text-bottom;
}

//Common Styles for Theme
.links {
  height: 20px;
  font-family: $primary-font-family-book;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: $primary-text-color;
  cursor: pointer;
}

.links:hover {
  color: $primary-text-color;
  text-decoration: underline;
}

/* Headings Start */

.header1,
.header2,
.header3,
.header4 {
  color: $primary-text-color;
  font-family: $primary-font-family-black;
  padding: 0px;
  text-align: left;
}

.header1 {
  line-height: 60px;
}

.header2 {
  line-height: 44px;
}

.header3 {
  line-height: 32px;
}

.header4 {
  line-height: 22px;
}

.sub-title {
  color: $primary-text-color;
  font-family: $primary-font-family-regular;
  font-size: 16px;
  padding: 10px 0;
  text-align: left;
}

/* Headings end */

/* Custom Confirm Model  Start*/

body .issueDialog .p-dialog-footer.p-dialog-footer .cancelBtn {
  padding-right: 30px !important;
  color: $primary-text-color !important;
  font-family: $primary-font-family-semibold !important;
}

body .issueDialog1 .p-dialog-footer.p-dialog-footer .cancelBtn {
  padding-right: 30px !important;
  color: $primary-text-color !important;
  font-family: $primary-font-family-semibold !important;
}

body .issueDialog .p-dialog-footer.p-dialog-footer {
  padding-top: 20px;
  padding-bottom: 20px;
}

body .issueDialog1 .p-dialog-footer.p-dialog-footer {
  padding-right: 40px !important;
  padding-bottom: 40px !important;
}

body .minWidthOverride1 {
  width: 255px !important;
}

body .auto-complete-panel {
  z-index: 99999 !important;
}

body .minWidthOverride {
  width: 255px !important;
}

.issueDialog {
  color: white;
  font-size: 18px !important;
  table tr:nth-child(odd) td {
    background-color: #ffffff !important;
  }
  .p-inputtext {
    font-size: 18px !important;
    color: $primary-text-color !important;
  }
  .successBlock {
    padding-top: 90px;
    padding-left: 100px;
  }
  .error-msg {
    color: $jnj-red;
  }
  .p-dialog-footer.p-dialog-footer button {
    width: 150px !important;
  }
  table tbody tr {
    height: 93px;
    border-bottom: 3px solid #f4f4f4 !important;
  }
  label span {
    padding-left: 2px;
  }
  .table th,
  .table td {
    padding: 10px 10px 0px 0px !important;
    border: none !important;
  }
  .p-dialog-header {
    height: 44px;
    padding: 15px 23px;
  }
  .p-dialog-footer {
    padding-left: 48px;
    padding-right: 48px !important;
    padding-bottom: 48px !important;
  }
  .p-dialog-content {
    padding-left: 48px !important;
    padding-right: 47px !important;
    .head1 {
      color: $secondary-text-color;
    }
    .head2 {
      color: $primary-text-color;
    }
    .issueFormBody {
      padding-top: 45px;
      label {
        color: $secondary-text-color;
        span {
          color: $jnj-red;
        }
      }
    }
    .issueBottom {
      padding-bottom: 5px;
    }
    .unitDrop {
      padding-top: 8px;
      width: 11.5vw;
    }
    .issuetbl {
      padding-top: 0px;
      max-height: 270px;
      overflow-x: hidden;
    }
    .links {
      padding-top: 42px;
    }
    .p-dialog-footer.p-dialog-footer {
      .cancelBtn {
        padding-right: 10px !important;
        color: $primary-text-color !important;
      }
    }
    em.minus {
      border-right: 2px solid lightgray;
      padding-top: 2px;
      padding-right: 3px;
      cursor: pointer;
      width: 25%;
      color: #63666a;
    }
    em.plus {
      border-left: 2px solid lightgray;
      padding-top: 2px;
      padding-left: 3px;
      cursor: pointer;
      width: 25%;
      color: #63666a;
    }
    .number-ticker input {
      width: 50%;
      text-align: center;
      border: none;
      height: 25px;
      padding: 0px !important;
    }
    .number-ticker {
      border-bottom: 2px solid $text-grey;
      padding-bottom: 4px;
      margin-top: 12px;
      width: 100%;
      min-width: 100px;
    }
    td.disable-row .number-ticker {
      border-bottom: 2px solid $disabled-text;
    }
    .addMore span {
      position: relative;
      bottom: 5px;
      padding-left: 15px;
      color: $primary-text-color;
      em {
        position: absolute;
        left: -10px;
        top: -1px;
      }
    }
  }
}

.issueDialog1 {
  color: white;
  background: #fff;
  table tr:nth-child(odd) td {
    background-color: #ffffff !important;
  }
  .p-inputtext {
    font-size: 18px !important;
    color: $primary-text-color !important;
  }
  .p-dialog-content {
    padding: 2vw 2vw !important;
  }
  .successBlock {
    padding-top: 90px;
    padding-left: 100px;
  }
  .error-msg {
    color: $jnj-red;
  }
  .minWidthOverride {
    width: 255px !important;
    border-right: none !important;
    border-left: none !important;
    border-top: none !important;
  }
  .p-dialog-footer.p-dialog-footer button {
    width: 150px !important;
  }
  table tbody tr {
    height: 93px;
  }
  label span {
    padding-left: 2px;
  }
  .table th,
  .table td {
    padding: 10px 10px 0px 0px !important;
    border: none !important;
  }
  .p-dialog-content {
    height: 662px !important;
    .head1 {
      color: $secondary-text-color;
    }
    .head2 {
      color: $primary-text-color;
    }
    .issueFormBody {
      padding-top: 45px;
      label {
        color: $secondary-text-color;
        margin-bottom: 20px;
        span {
          color: $jnj-red;
        }
      }
    }
    .issueBottom {
      padding-bottom: 5px;
    }
    .unitDrop {
      padding-top: 8px;
      width: 12vw;
    }
    .links {
      padding-top: 42px;
    }
    .p-dialog-footer.p-dialog-footer {
      .cancelBtn {
        padding-right: 10px !important;
        color: $primary-text-color !important;
      }
    }
    em.minus {
      border-right: 2px solid lightgray;
      padding-top: 2px;
      padding-right: 3px;
      cursor: pointer;
      width: 25%;
      color: #63666a;
    }
    em.plus {
      border-left: 2px solid lightgray;
      padding-top: 2px;
      padding-left: 3px;
      cursor: pointer;
      width: 25%;
      color: #63666a;
    }
    .number-ticker input {
      width: 50%;
      text-align: center;
      border: none;
      height: 25px;
      padding: 0px !important;
    }
    .number-ticker {
      border-bottom: 2px solid $text-grey;
      padding-bottom: 5px;
      margin-top: 12px;
      width: 100%;
      min-width: 100px;
    }
    td.disable-row .number-ticker {
      border-bottom: 2px solid $disabled-text;
    }
    .addMore span {
      position: relative;
      bottom: 6px;
      padding-left: 13px;
      color: $primary-text-color;
      em {
        position: absolute;
        left: -10px;
        top: -1px;
      }
    }
  }
}

// .customDialog .p-dialog-header,
// .customDialog .p-dialog-content,
// .customDialog .p-dialog-footer.p-dialog-footer

.customDialog .p-dialog-header,
.customDialog .p-dialog-content,
.customDialog .p-dialog-footer {
  background: #ffffff !important;
  border: 0px !important;
  border-radius: initial;
}

// .customDialog .p-component-content p {
//   font-size: 28px;
// }
.customDialog .p-dialog-footer.p-dialog-footer .cancel {
  margin-right: 25px;
}

.customDialog .p-dialog-footer.p-dialog-footer button {
  width: 100px;
}

.customDialogReconcile {
  .p-dialog-header,
  .p-dialog-content,
  .p-dialog-footer.p-dialog-footer {
    background: #ffffff !important;
    border: 0px !important;
    border-radius: initial;
  }
  .p-dialog-footer.p-dialog-footer .cancel {
    margin-right: 25px;
  }
  .p-dialog-footer.p-dialog-footer button {
    width: 100px;
  }
  .p-dialog-footer {
    padding-right: 24px !important;
    padding-bottom: 24px !important;
  }
}

.customDialogReplishmentActivate {
  .p-dialog-header,
  .p-dialog-content,
  .p-dialog-footer.p-dialog-footer {
    background: $jnj-light-orange !important;
    border: 1px solid $primary-text-color !important;
    border-radius: initial;
  }
  .p-dialog-footer.p-dialog-footer .cancel {
    margin-right: 25px;
  }
  .p-dialog-footer.p-dialog-footer button {
    width: 100px;
  }
  .p-dialog-footer {
    padding-right: 24px !important;
    padding-bottom: 24px !important;
  }
}

/*Custom Confirm Model End*/

/*login progres*/

.circle-dpinner circle,
.mat-spinner circle {
  stroke: $jnj-red !important;
}

body
  .reconciliation-edit
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item {
  color: $text-grey;
  font-family: $primary-font-family-medium;
}

body
  .reconciliation-edit
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item.p-highlight {
  font-family: $primary-font-family-black;
  color: $text-grey;
  background-color: #ffffff;
}

body
  .units-list
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item {
  color: $text-grey;
  font-family: $primary-font-family-medium;
}

body
  .units-list
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item.p-highlight {
  font-family: $primary-font-family-black;
  color: $text-grey;
  background-color: #ffffff;
}

// table tr:nth-child(odd) td div.number-ticker input {
//   background-color: rgba(92, 151, 191, 0.0) !important;
// }

/*Prime NG Table Auto Complete Start*/

.customAutoComplete .p-autocomplete-dropdown.p-button.p-component {
  background-color: #ffffff;
  border: none;
  border-bottom: 1px solid #333333;
  border-radius: 0px;
  color: black;
  margin-top: 2px;
}

body .p-inputtext:enabled:focus:not(.p-state-error) {
  border-color: #333333 !important;
}

input.CustomTableAutocomplete {
  width: 300px !important;
}

.p-autocomplete-emptymessage {
  color: $jnj-red !important;
}

.CustomUnitTableAutocomplete.p-inputtext.p-component {
  width: 100% !important;
  font-size: 18px;
}

input.CustomUnitTableAutocomplete {
  width: 100% !important;
}

/*Table auto complete End*/

/* Custom Confirm Model end */

/* Misc styles start */

a.p-state-active,
span.p-state-active {
  background-color: $jnj-red !important;
}

.red {
  color: $jnj-red !important;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

/* Misc styles end */

.search-bar {
  padding: 0px 0.95px;
  height: 96px;
  .input-group input {
    color: $primary-text-color;
  }
  .input-group {
    padding: 0px;
    border: 3px solid #f4f4f4 !important;
  }
  .textbox {
    border: none;
    padding: 0px !important;
    height: 100%;
  }
  .input-group-text {
    background: none;
    border: none;
  }
  .dropdown-item {
    border-top: 1px solid $primary-border-color;
    &:last-child {
      border-bottom: 1px solid $primary-border-color;
    }
  }
}
.pi-chevron-down:before {
  font-family: $icon-font;
  content: "keyboard_arrow_down";
  -webkit-font-feature-settings: "liga" 1;
  -moz-font-feature-settings: "liga" 1;
  font-feature-settings: "liga" 1;
  font-size: 1.8em;
}
.p-dropdown-open .pi-chevron-down:before {
  content: "keyboard_arrow_up";
}
.p-inputwrapper-filled {
  width: 100%;
  .p-dropdown.p-component {
    border: 0px;
    line-height: 29px;
    padding-left: 5px;
    width: 100% !important;
    min-width: auto;
    background-color: transparent;
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 2px;
      height: 100%;
    //   background-color: $light-grey;
      left: 0px;
    }
    .p-dropdown-trigger {
      color: $text-grey;
      right: 16.5%;
      top: -10px;
      .pi-chevron-down {
        margin-top: -7px;
        margin-left: -0.8em;
      }
    }
    .p-dropdown-label-container {
      .p-dropdown-label {
        font-family: $primary-font-family-bold;
        color: $text-grey !important;
        font-size: 18px;
      }
    }
    .p-dropdown-panel.p-component {
      border: 0px;
      box-shadow: none;
      right: 16.5%;
      width: auto;
      min-width: auto;
      margin-top: 0em;
      z-index: 999 !important;
      top: 73px !important;
      .p-dropdown-items {
        .p-dropdown-item {
          padding: 0px;
          text-indent: 24px;
          border-bottom: 0px;
          font-family: $primary-font-family-medium;
          background-color: #ffffff;
          .dropdown-label {
            height: 36px;
            line-height: 36px;
            border: 1px solid $grey-border;
            border-top: 0px;
            cursor: pointer;
          }
          &:hover {
            background-color: #ffffff;
            color: $jnj-red !important;
            font-family: $primary-font-family-bold;
            border: 0px;
          }
        }
        &.p-dropdown-list .ng-star-inserted:first-child li.p-dropdown-item {
          &:first-child {
            font-family: $primary-font-family-semibold;
            color: $primary-text-color !important;
            &:hover {
              background-color: #ffffff;
              color: $primary-text-color !important;
              font-family: $primary-font-family-semibold;
            }
          }
        }
      }
    }
  }
}
.status-dropdown {
  .pi-chevron-down:before {
    font-family: $icon-font;
    content: "keyboard_arrow_down";
    -webkit-font-feature-settings: "liga" 1;
    -moz-font-feature-settings: "liga" 1;
    font-feature-settings: "liga" 1;
    font-size: 1.8em;
  }
  .p-dropdown-open .pi-chevron-down:before {
    content: "keyboard_arrow_up";
  }
  .p-inputwrapper-filled {
    width: 100%;
    .p-dropdown.p-component {
      border: 0px;
      line-height: 29px;
      padding-left: 16px;
      width: 100% !important;
      min-width: auto;
      background-color: transparent;
      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 2px;
        height: 100%;
        background-color: $light-grey;
        left: 0px;
      }
      .p-dropdown-trigger {
        color: $text-grey;
        right: 16.5%;
        top: -10px;
        .pi-chevron-down {
          margin-top: -7px;
          margin-left: -0.8em;
        }
      }
      .p-dropdown-label-container {
        .p-dropdown-label {
          font-family: $primary-font-family-bold;
          color: $text-grey !important;
          font-size: 18px !important;
        }
      }
      .p-dropdown-panel.p-component {
        border: 0px;
        box-shadow: none;
        right: 16.5%;
        width: auto;
        min-width: auto;
        margin-top: 0em;
        z-index: 999 !important;
        top: 73px !important;
        .p-dropdown-items {
          .p-dropdown-item {
            padding: 0px;
            text-indent: 24px;
            border-bottom: 0px;
            font-family: $primary-font-family-medium;
            background-color: #ffffff;
            .dropdown-label {
              height: 36px;
              line-height: 36px;
              border: 1px solid $grey-border;
              border-top: 0px;
              cursor: pointer;
            }
            &:hover {
              background-color: #ffffff;
              color: $jnj-red !important;
              font-family: $primary-font-family-bold;
              border: 0px;
            }
          }
          &.p-dropdown-list .ng-star-inserted:first-child li.p-dropdown-item {
            &:first-child {
              font-family: $primary-font-family-semibold;
              color: $primary-text-color !important;
              &:hover {
                background-color: #ffffff;
                color: $primary-text-color !important;
                font-family: $primary-font-family-semibold;
              }
            }
          }
        }
      }
    }
  }
}

.pi-chevron-down:before {
  color: $text-grey;
  font-size: 22px;
}

.productIssuePopup .pi-chevron-down:before {
  color: $text-grey;
  padding-left: 8px !important;
  line-height: 24px;
}

.productEdit {
  .pi-chevron-down:before {
    font-family: $icon-font;
    content: "keyboard_arrow_down";
    -webkit-font-feature-settings: "liga" 1;
    -moz-font-feature-settings: "liga" 1;
    font-feature-settings: "liga" 1;
    font-size: 15px !important;
    padding-left: 5px !important;
  }
  .p-dropdown-open .pi-chevron-down:before {
    content: "keyboard_arrow_up";
  }
}

.sutUtil {
  .pi-chevron-down:before {
    font-family: $icon-font;
    content: "keyboard_arrow_down";
    -webkit-font-feature-settings: "liga" 1;
    -moz-font-feature-settings: "liga" 1;
    font-feature-settings: "liga" 1;
  }
  .p-dropdown-open .pi-chevron-down:before {
    content: "keyboard_arrow_up";
  }
}

.sutUtil .ng-invalid .pi-chevron-down:before {
  font-size: 15px !important;
  padding-left: 5px !important;
  color: $jnj-red !important;
}

.sutUtil .ng-valid .pi-chevron-down:before {
  font-size: 15px !important;
  padding-left: 5px !important;
  color: $text-grey !important;
}

/*Autocomplete*/

.header {
  .status-dropdown .p-dropdown.p-component {
    .p-dropdown-trigger {
      right: -2%;
      .pi-chevron-down {
        margin-top: -10px;
        font-size: 20px;
      }
    }
    .p-dropdown-panel.p-component {
      right: 0px;
      top: 59px !important;
    }
  }
  .search-bar {
    height: 72px;
    .input-group {
      padding: 0em;
      border: 0px !important;
      .customSearchAutocomplete {
        .p-autocomplete {
          .p-autocomplete-panel {
            margin-top: 6px;
            left: -30px !important;
          }
          &:after {
            font-size: 2.45em;
            left: -10px;
            top: 6px;
          }
          .p-inputtext.p-component {
            text-indent: 40px;
            line-height: 70px;
            padding: 0px;
          }
        }
        &.p-inputwrapper-filled {
          .p-autocomplete {
            .p-inputtext.p-component {
              text-indent: 0px !important;
            }
          }
        }
      }
      .input-group-append {
        width: 175px;
        padding: 1em 0rem;
      }
    }
    &:before {
      content: "";
      height: 3px;
      width: 100%;
      position: absolute;
      background-color: $primary-border-color;
      left: 0px;
      right: 0px;
      z-index: 90;
    }
  }
}

.customSearchAutocomplete.p-inputwrapper-filled {
  .p-autocomplete {
    &:after {
      display: none;
    }
  }
  .p-inputtext.p-component {
    text-indent: 18px;
  }
}

.header {
  .status-dropdown .p-dropdown.p-component {
    .p-dropdown-trigger {
      right: -2%;
      .pi-chevron-down {
        margin-top: -10px;
        font-size: 20px;
      }
    }
    .p-dropdown-panel.p-component {
      right: 0px;
      top: 59px !important;
    }
  }
  .search-bar {
    height: 72px;
    .input-group {
      padding: 0em;
      border: 0px !important;
      .customautoSearchAutocomplete {
        .p-autocomplete {
          .p-autocomplete-panel {
            margin-top: 6px;
            left: 0px !important;
          }
          &:after {
            font-size: 2.8em;
            left: -10px;
            top: 6px;
          }
          .p-inputtext.p-component {
            text-indent: 40px;
            line-height: 70px;
            padding: 0px;
          }
        }
        &.p-inputwrapper-filled {
          .p-autocomplete {
            .p-inputtext.p-component {
              text-indent: 0px !important;
            }
          }
        }
      }
    }
    &:before {
      content: "";
      height: 3px;
      width: 100%;
      position: absolute;
      background-color: $primary-border-color;
      left: 0px;
      right: 0px;
      z-index: 90;
    }
  }
}

.customautoSearchAutocomplete.p-inputwrapper-filled {
  .p-autocomplete {
    &:after {
      display: none;
    }
  }
  .p-inputtext.p-component {
    text-indent: 28px;
  }
}

.CustomTableAutocomplete .p-inputtext.p-component {
  font-size: 18px;
}

/*Sort Icon overide*/

.pi-sort-amount:before {
  font-family: $icon-font;
  content: "keyboard_arrow_down";
  font-feature-settings: "liga" 1;
  font-size: 1.6em;
}

.pi-sort-amount-up:before,
.pi-sort-amount-up-alt:before,
.pi-sort-alt:before {
  font-family: $icon-font;
  content: "keyboard_arrow_up";
  font-feature-settings: "liga" 1;
  font-size: 1.6em;
}

.pi-sort-amount-down:before,
.pi-sort-amount-down-alt:before {
  font-family: $icon-font;
  content: "keyboard_arrow_down";
  font-feature-settings: "liga" 1;
  font-size: 1.6em;
}

.customautoSearchAutocomplete.p-inputwrapper-filled .p-inputtext.p-component,
.customSearchAutocomplete.p-inputwrapper-filled .p-inputtext.p-component {
  padding-right: 65px;
}

body
  .p-datatable
  .p-sortable-column:not(.p-highlight):hover
  .p-sortable-column-icon {
  color: #848484 !important;
}

body .p-treetable-toggler .pi-chevron-right:before {
  content: "\e90d" !important;
}

body .p-treetable-toggler .pi-chevron-down:before {
  content: "\e90f" !important;
}

.customautoSearchAutocomplete {
  border-right: 0px;
  padding: 0.5em 0.75rem 0.5em 0 !important;
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
    -webkit-mask: url("/assets/img/close-24px.svg");
    -webkit-mask: url("/assets/img/close-24px.svg");
    background-color: #63666a;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    cursor: pointer;
    font-size: 5em !important;
    right: 32px;
    height: 24px;
    width: 24px;
    color: $text-grey !important;
  }
  @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    input[type="search"]::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }
  .history.material-icons {
    display: none;
  }
  .history .history.material-icons {
    display: inline-block;
    vertical-align: middle;
    color: $jnj-red;
    margin-top: -3px;
    width: 30px;
    font-size: 28px;
    margin-left: -10px;
    margin-right: 5px;
  }
  .material-icons.history + div {
    font-size: 14px;
    margin: 5px 10px 5px 0;
    padding-left: 0.5em;
    font-family: $primary-font-family-medium !important;
  }
  .history .history.material-icons + div {
    display: inline-block;
    color: $jnj-red;
    padding-left: 0px;
    font-family: $primary-font-family-semibold !important;
  }
  .p-inputtext.p-component {
    border-bottom: 0px;
    text-indent: 75px;
    font-size: 18px;
    padding: 0em 0.5em;
    line-height: 5.1em;
  }
  .p-corner-all {
    border-radius: 0px;
  }
  .p-helper-clearfix.ng-star-inserted {
    padding: 8px 20px 9px;
    border: 1px solid #e2e2e2;
    border-top: 0px;
  }
  .p-autocomplete {
    width: 100%;
    //text-indent: 5px;
    &:after {
      font-family: $icon-font;
      content: "search";
      -webkit-font-feature-settings: "liga" 1;
      -moz-font-feature-settings: "liga" 1;
      font-feature-settings: "liga" 1;
      left: 0.4em;
      position: absolute;
      display: block;
      cursor: text;
      top: 10px;
      font-size: 52px;
      color: $text-grey !important;
      padding-right: 50px;
    }
    .p-helper-clearfix.ng-star-inserted {
      padding: 8px 20px 9px;
      border: 1px solid #e2e2e2;
      border-top: 0px;
    }
    .p-autocomplete-panel {
      border: 0px;
      box-shadow: none;
      right: -7px;
      font-family: $primary-font-family-book;
      z-index: 999;
      left: -2px !important;
      .p-autocomplete-items {
        .p-autocomplete-list-item {
          padding: 1em 2.6em;
          color: #63666a !important;
          font-family: $primary-font-family-medium !important;
          font-size: 14px !important;
          border: 1px solid #f4f4f4;
          &.p-highlight {
            color: $primary-text-color;
            background-color: #ffffff;
            font-weight: bold;
          }
        }
        .p-autocomplete-loader {
          right: 0.5em;
        }
        .p-autocomplete-panel {
          border: 0px;
          box-shadow: none;
          margin-top: 2px;
          right: 0px;
          font-family: $primary-font-family-book;
          .p-autocomplete-items {
            .p-autocomplete-list-item {
              padding: 0px;
              color: $primary-text-color;
              height: 48px;
              &.p-highlight {
                color: $primary-text-color;
                background-color: #ffffff;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}

.customSearchAutocomplete {
  // border-right: 0px;
  padding: 0.5em 0.75rem 0.5em 0 !important;
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
    -webkit-mask: url("/assets/img/close-24px.svg");
    -webkit-mask: url("/assets/img/close-24px.svg");
    background-color: #63666a;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    cursor: pointer;
    font-size: 2em;
    right: 32px;
    height: 24px;
    width: 24px;
  }
  @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    input[type="search"]::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }
  .history.material-icons {
    display: none;
  }
  .history .history.material-icons {
    display: inline-block;
    vertical-align: middle;
    color: $jnj-red;
    padding-left: 0.1em;
  }
  .material-icons.history + div {
    font-size: 14px;
    margin: 5px 10px 5px 0;
    padding-left: 0.5em;
    font-family: $primary-font-family-medium !important;
  }
  .history .history.material-icons + div {
    display: inline-block;
    color: $jnj-red;
    padding-left: 0px;
    font-family: $primary-font-family-semibold !important;
  }
  .p-inputtext.p-component {
    border-bottom: 0px;
    text-indent: 75px;
    font-size: 18px;
    padding: 0em 0.5em;
    line-height: 5.1em;
  }
  .p-corner-all {
    border-radius: 0px;
  }
  .p-helper-clearfix.ng-star-inserted {
    padding: 11px;
  }
  .p-autocomplete {
    width: 100%;
    // text-indent: 5px;
    &:after {
      font-family: $icon-font;
      content: "search";
      -webkit-font-feature-settings: "liga" 1;
      -moz-font-feature-settings: "liga" 1;
      font-feature-settings: "liga" 1;
      left: 0.4em;
      position: absolute;
      display: block;
      cursor: text;
      top: 10px;
      font-size: 52px;
      color: $text-grey;
    }
    .p-helper-clearfix.ng-star-inserted {
      padding: 8px 20px 9px;
      border: 1px solid #e2e2e2;
      border-top: 0px;
    }
    .p-autocomplete-panel {
      border: 0px;
      box-shadow: none;
      margin-top: 2px;
      right: 0px;
      font-family: $primary-font-family-book;
      z-index: 999;
      left: -3px !important;
      .p-autocomplete-items {
        .p-autocomplete-list-item {
          padding: 0px;
          color: $primary-text-color;
          height: 48px;
          &.p-highlight {
            color: $primary-text-color;
            background-color: #ffffff;
            font-weight: bold;
          }
        }
        .p-autocomplete-loader {
          right: 0.5em;
        }
        .p-autocomplete-panel {
          border: 0px;
          box-shadow: none;
          margin-top: 2px;
          right: 0px;
          font-family: $primary-font-family-book;
          .p-autocomplete-items {
            .p-autocomplete-list-item {
              padding: 0px;
              color: $primary-text-color;
              height: 48px;
              &.p-highlight {
                color: $primary-text-color;
                background-color: #ffffff;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}

.search-results {
  .p-datatable.p-component {
    margin-bottom: 35px;
    &:last-child {
      margin-bottom: 0px;
    }
    // width: 45.7vw;
  }
  .p-datatable-caption {
    &.p-component-header {
      background: none;
      text-align: left;
      border: 0px;
      padding-left: 0px;
      .tbl-caption {
        margin: 0px;
        margin-top: 0.5rem;
        color: $primary-text-color;
      }
    }
  }
  .p-datatable .p-datatable-thead > tr > th {
    border: 0px;
    background: none;
    position: relative;
    color: $primary-text-color;
    width: 33.3%;
    height: 72px;
    padding: 0px;
    padding-right: 10px;
    font-size: 16px;
  }
  .p-datatable .p-datatable-tbody > tr {
    &:nth-child(even) {
      background: none;
    }
  }
  .p-datatable .p-datatable-tbody > tr > td {
    border: 3px solid $primary-border-color;
    border-left: 0px;
    border-right: 0px;
    background: none !important;
    color: $primary-text-color;
    height: 96px;
    font-size: 18px;
    line-height: 22px;
    padding: 0px;
    padding-right: 10px;
  }
}

.search-bar {
  .p-autocomplete-loader {
    display: none !important;
  }
}

/* staff-permission module */

.staff-permission {
  .tabHeader {
    padding: 0px;
  }
  .p-datatable .p-datatable-thead > tr > th {
    border: none;
    color: $primary-body-color;
    background-color: $primary-body-color;
    // padding: 8px;
    padding: 16px 8px;
  }
  /*  custom-p-dropdown page */
  .add-user {
    .p-dropdown.p-component {
      // border: none;
      // border-bottom: 0px;
      min-width: 1vw;
    }
    .p-inputtext.p-component {
      border: none;
      border-bottom: 2px solid $text-grey !important;
      font-size: 18px;
    }
    .p-component-header .p-inputtext,
    .p-component-content .p-inputtext {
      font-size: 14px;
      // padding-left: 0px;
    }
    .p-inputtext:enabled:focus:not(.p-state-error) {
      border-color: #212121;
      // 2px solid #212121
    }
    .p-dropdown label.p-dropdown-label {
      padding-left: 0px;
      border-bottom: 2px solid $text-grey;
      border-radius: 0px;
      font-size: 18px !important;
      color: $primary-text-color !important;
    }
    input.ng-touched.ng-invalid,
    .custom-p-dropdown.ng-touched.ng-invalid
      > .p-dropdown
      > .p-dropdown-label-container
      > .p-dropdown-label {
      // border: none;
      border-radius: 0px;
    }
    input.p-inputtext.p-corner-all.p-component::placeholder {
      // input.p-inputtext.p-corner-all.p-state-default.p-component.ng-dirty.ng-touched.ng-invalid::placeholder {
      /* color: red !important; */
      opacity: 1 !important;
      color: $primary-text-color !important;
    }
    .p-dropdown .p-dropdown-trigger {
      background-color: #ffffff00;
    }
    .p-dropdown-panel {
      border: none;
      box-shadow: none;
    }
    //   .p-dropdown-panel .p-dropdown-items-wrapper {
    //     overflow: unset;
    //     max-height: unset !important;
    // }
    .p-dropdown-open .pi-chevron-down::before {
      content: "\e903";
    }
    .p-dropdown-panel {
      z-index: 99999 !important;
    }
  }
  /*  add-user page */
  /*  add-user-success page */
  /*  add-user-success page */
  /* manage user page */
  .list-user {
    .edit {
      .p-dropdown-panel {
        border: 1px solid #c8c8c8;
        background-color: #ffffff;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      }
    }
    .pi-sort:before {
      content: "keyboard_arrow_up" !important;
    }
    // .p-datatable .p-sortable-column:not(.p-highlight):hover {
    //   background-color: $jnj-blue !important;
    //   color: $table-head-text-color !important;
    //   .p-sortable-column-icon {
    //     color: #848484 !important;
    //   }
    // }
    .p-datatable .p-datatable-tbody > tr:nth-child(even) {
      background-color: #fff;
    }
    td {
      border: none !important;
      word-wrap: break-word;
    }
    .p-datatable .p-datatable-tbody > tr {
      color: #212121 !important;
    }
    .p-datatable .p-datatable-caption,
    .p-datatable .p-datatable-summary {
      
      color: $text-grey;
      border: none;
      text-align: left;
      background-color: #fff !important;
    }
    .p-datatable .p-datatable-caption a {
      font-size: 11px;
      cursor: pointer;
    }
    // .p-datatable .p-datatable-tbody > tr > td {
    //   padding: 0.25em 0.5em !important;
    // }
    .p-datatable .p-datatable-thead > tr > th {
      font-family: $primary-font-family-black;
      font-size: 18px;
      color: $table-head-text-color;
    }
    .p-datatable .p-datatable-tbody {
      font-family: $primary-font-family-regular;
      font-size: 18px;
      thead {
        background-color: $jnj-blue;
      }
      tr {
        height: 96px;
        border-bottom: 1px solid #d8d8d8;
      }
      td {
        position: relative;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
      }
    }

  }
  .user-tbl {
    .p-inputtext:enabled:focus:not(.p-state-error) {
      border-color: $primary-text-color !important;
    }
    .manag-permis-typ .p-dropdown label.p-dropdown-label {
      color: $primary-text-color !important;
      border-color: $primary-text-color !important;
    }
    min-height: 375px;
    .p-inputtext {
      color: $primary-text-color !important;
      text-align: left;
    }
    margin-top: 50px;
    table {
      font-family: $primary-font-family-book;
      thead {
        background-color: $primary-body-color;
        color: $table-head-text-color;
        th {
          height: 48px;
          font-family: $primary-font-family-black;
          font-size: 18px;
        }
      }
    }
    // .p-dropdown.p-component {
    //     // border: none;
    //     // border-bottom: 0px;
    // }
    .p-inputtext.p-component {
      border: none;
      border-bottom: 2px solid $text-grey;
      font-size: 18px;
    }
    .p-dropdown label.p-dropdown-label {
      padding-left: 0px;
      border-bottom: 2px solid $text-grey;
      border-radius: 0px;
      font-size: 18px;
    }
    input.ng-touched.ng-invalid,
    .custom-p-dropdown.ng-touched.ng-invalid
      > .p-dropdown
      > .p-dropdown-label-container
      > .p-dropdown-label {
      border: none;
      border-bottom: 3px solid $jnj-red;
      border-radius: 0px;
    }
    .custom-p-dropdown.ng-touched.ng-invalid .p-dropdown-trigger {
      border: none;
      border-bottom: 3px solid $jnj-red !important;
      border-radius: 0px;
    }
    .p-dropdown .p-dropdown-trigger {
      background-color: #ffffff00;
      //border-bottom: 2px solid $text-grey !important;
    }
    .no-arrow .p-dropdown .p-dropdown-trigger {
      background-color: #ffffff00;
      border-bottom: 2px solid $text-grey !important;
      display: none;
    }
    .p-dropdown-panel {
      border: none;
      box-shadow: none;
    }
    .error-msg {
      color: $jnj-red;
      display: block;
      position: absolute;
      //top: 65px;
    }
    // .p-datatable-scrollable-view{
    //   padding-bottom: 60px;
    // }
  }
  /* manage user page ends */
  /* approve access page */
  .access-tbl {
    .p-inputtext:enabled:focus:not(.p-state-error) {
      border-color: $primary-text-color !important;
    }
    .p-chkbox-box {
      width: 20px;
      height: 20px;
      border: solid 2px #d8d8d8 !important;
      background-color: #ffffff !important;
      cursor: pointer;
      //margin-right: 10px;
    }
    .p-chkbox .p-chkbox-box.p-state-active:not(.p-disabled) {
      width: 20px;
      height: 20px;
      border: solid 2px #e02d00 !important;
      background-color: #e02d00 !important;
      cursor: pointer;
    }
    .pi-check:before {
      content: "" !important;
    }
    .p-inputtext {
      color: $primary-text-color !important;
      padding-left: 0px;
      font-size: 18px;
      ::-webkit-input-placeholder:after {
        content: " *";
        color: red;
      }
    }
    .p-datatable .p-datatable-tbody > tr:nth-child(even) {
      background-color: #fff;
    }
    .p-datatable .p-datatable-thead > tr > th {
      height: 48px;
      font-family: $primary-font-family-black;
      font-size: 18px;
      color: $table-head-text-color;
    }
    .p-datatable .p-datatable-tbody > tr {
      color: #212121 !important;
    }
    .p-datatable .p-datatable-tbody {
      font-family: $primary-font-family-book;
      font-size: 14px;
      thead {
        background-color: $jnj-blue;
      }
      tr {
        height: 96px;
      }
      td {
        position: relative;
        padding-left: 6px;
      }
    }
    input::-webkit-input-placeholder {
      font-size: 14px;
      font-family: $primary-font-family-book;
    }
    // .p-dropdown.p-component {
    //     // border: none;
    //     // border-bottom: 0px;
    // }
    .p-dropdown label.p-dropdown-label {
      padding-left: 0px;
      border-bottom: 2px solid $text-grey;
      border-radius: 0px;
      text-overflow: ellipsis;
    }
    input.ng-touched.ng-invalid,
    .custom-p-dropdown.ng-touched.ng-invalid
      > .p-dropdown
      > .p-dropdown-label-container
      > .p-dropdown-label {
      border: none;
      border-bottom: 3px solid $jnj-red;
      border-radius: 0px;
    }
    .custom-p-dropdown.ng-touched.ng-invalid .p-dropdown-trigger {
      border: none;
      border-bottom: 3px solid $jnj-red !important;
      border-radius: 0px;
      color: $jnj-red !important;
    }
    .p-dropdown .p-dropdown-trigger {
      background-color: #ffffff00;
      //border-bottom: 0px solid $text-grey !important;
    }
    .no-arrow .p-dropdown .p-dropdown-trigger {
      background-color: #ffffff00;
      border-bottom: 0px solid $text-grey !important;
      display: none !important;
    }
    .p-dropdown-panel {
      border: none;
      box-shadow: none;
    }
    .p-dropdown {
      min-width: 0px !important;
    }
    .error-msg {
      color: $jnj-red;
      display: block;
      position: absolute;
      top: 72px;
    }
  }
  /* approve access page ends */
}

/* staff-permission module */

.units-list {
  // .pi-sort:before {
  //   content: "keyboard_arrow_up" !important;
  // }
  .p-inputtext:enabled:focus:not(.p-state-error) {
    border-color: $primary-text-color !important;
  }
  .p-autocomplete .p-autocomplete-input {
    padding-left: 0px;
    padding-bottom: 0px !important;
    
  }
  .p-inputtext {
    color: $primary-text-color !important;
    padding-left: 0px;
    font-size: 18px;
    margin-bottom: -10px !important;
    padding-bottom: 12px !important;
    ::-webkit-input-placeholder:after {
      content: " *";
      color: red;
    }
  }
  .p-datatable .p-datatable-tbody > tr:nth-child(even) {
    background-color: #fff;
  }
  .p-datatable .p-datatable-thead > tr > th {
    height: 48px;
    font-family: $primary-font-family-black;
    font-size: 18px;
    color: $table-head-text-color;
  }
  .p-datatable .p-datatable-tbody > tr {
    color: #212121 !important;
  }
  .p-datatable .p-datatable-tbody {
    font-family: $primary-font-family-book;
    font-size: 14px;
    thead {
      background-color: $jnj-blue;
    }
    // tr {
    //     // height: 96px;
    // }
    tr.error-row {
      height: 210px !important;
    }
    td {
      position: relative;
      padding-left: 6px;
      word-wrap: break-word;
      overflow: visible;
    }
  }
  input::-webkit-input-placeholder {
    font-size: 18px;
    font-family: $primary-font-family-regular !important;
    color: $primary-text-color !important;
  }
  .p-dropdown.p-component {
    border: none;
    border-bottom: 0px;
  }
  .p-dropdown span.p-dropdown-label {
    padding-left: 0px;
    border-bottom: 2px solid $text-grey;
    border-radius: 0px;
  }
  input.ng-touched.ng-invalid,
  .custom-p-dropdown.ng-touched.ng-invalid
    > .p-dropdown
    > .p-dropdown-label-container
    > .p-dropdown-label {
    border: none;
    border-bottom: 3px solid $jnj-red;
    border-radius: 0px;
  }
  .custom-p-dropdown.ng-touched.ng-invalid .p-dropdown-trigger {
    border: none;
    //border-bottom: 3px solid $jnj-red !important;
    border-radius: 0px;
  }
  .p-dropdown .p-dropdown-trigger {
    background-color: #ffffff00;
    border-bottom: 0px solid $text-grey !important;
  }
  .no-arrow .p-dropdown .p-dropdown-trigger {
    background-color: #ffffff00;
    border-bottom: 0px solid $text-grey !important;
    display: none !important;
  }
  .p-dropdown-panel {
    border: none;
    box-shadow: none;
  }
  .p-dropdown {
    min-width: 0px !important;
  }
  .error-msg {
    color: $jnj-red;
    display: block;
    position: absolute;
    padding-top: 10px !important;
    //top: 73px;
  }
  .edit-icon {
    position: relative !important;
    right: 8px !important;
    top: 0 !important;
  }
}

.productIssues {
  .p-datatable .p-datatable-tbody > tr:nth-child(even) {
    background-color: #ffffff !important;
  }
}

.product-list {
  .p-datatable .p-datatable-tbody > tr:nth-child(even) {
    background-color: #fff !important;
  }
  .p-datatable .p-datatable-tbody {
    tr {
      border-bottom: 3px solid #f4f4f4 !important;
    }
  }
}

body ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

body ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $grey-border;
}

.material-icons.material-icons-outlined {
  font-family: $icon-font-outlined;
}

.light-orange {
  color: $jnj-light-orange !important;
}

.green {
  color: $jnj-green !important;
}

// notification tab account settings
.p-selectbutton .p-button {
  width: 138px;
  border-radius: 0px;
  padding: 11px;
}

.p-selectbutton .p-button:last-child {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  font-family: $primary-font-family-semibold !important;
}

.p-selectbutton .p-button:first-child {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  font-family: $primary-font-family-semibold !important;
}

.p-selectbutton .p-button.p-state-active {
  border-color: $jnj-blue !important;
  background-color: $jnj-blue !important;
  color: $primary-body-color !important;
  font-family: $primary-font-family-semibold !important;
}

// .ph-input .p-inputtext {
// color: $disabled-text !important;
// border-bottom: 2px solid $disabled-text !important;
// }
.ph-top-space span {
  font-size: 12px !important;
  color: $disabled-text !important;
}

.ph-top-space span:after {
  content: "*";
  color: $disabled-text;
}

.notif-tab {
  .ph-input.ng-dirty.ng-invalid > .p-inputtext {
    border: 0px;
    border-bottom: 3px solid $jnj-red !important;
  }
  .p-inputtext:enabled:focus:not(.p-state-error) {
    border-color: unset;
    outline: 0 none;
    box-shadow: none;
  }
  .p-dropdown.p-component,
  .p-inputtext.p-component {
    font-size: 18px;
  }
}

//user-setup topic
.user-setup .topic p {
  width: 680px;
  max-width: 710px !important;
}

// notification tab account settings
.account-setting-page {
  .account-setting {
    .p-selectbutton .p-button {
      background-color: $primary-border-color;
      border: 0px;
      color: $notifi-font-clr;
      height: 48px;
    }
    .notif-tab .p-selectbutton .p-button {
      padding: 8.5px !important;
    }
  }
}

.p-calendar {
  width: 576px;
}

.alert-warning {
  border-radius: 0px;
}

.recall {
  ::-webkit-input-placeholder {
    color: $primary-text-color !important;
    font-size: 18px;
  }
  .calendar.ng-invalid {
    .p-component {
      ::-webkit-input-placeholder {
        color: $jnj-red !important;
      }
    }
  }
}

.schedule-list {
  .p-datatable .p-datatable-tbody > tr {
    cursor: pointer;
    td {
      height: 43px !important;
      border: 0.1px solid $secondary-border-color !important;
      padding: 0.75em 0.6em !important;
    }
  }
}

.case-status-comp {
  .p-datatable .p-datatable-tbody > tr {
    cursor: pointer;
    td {
      height: 96px;
    }
  }
  .p-datatable .p-datatable-tbody > tr:nth-child(even) {
    background-color: $primary-body-color !important;
  }
  .p-datatable.p-datatable-hoverable-rows
    .p-datatable-tbody
    > tr.p-selectable-row:not(.p-highlight):not(.p-contextmenu-selected):hover {
    cursor: pointer;
    background-color: $primary-body-color !important;
  }
}

.cursor {
  cursor: pointer;
}

.dark-red {
  color: $jnj-dark-red;
}

.text-grey {
  color: $text-grey;
}

.navigate-icon {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  width: 40px;
  display: inline-block;
  &.back {
    background-image: url("/assets/img/arrow_back.svg");
    background-image: url("/assets/img/arrow_back.svg");
  }
  &.forward {
    background-image: url("/assets/img/arrow_forward.svg");
    background-image: url("/assets/img/arrow_forward.svg");
  }
}

// Tooltip Customizations
.filter-tooltip,
.product-tooltip,
.quantity-tooltip {
  .p-tooltip-text {
    background-color: $primary-body-color !important;
    border: 1px solid $input-enabled-border;
    border-radius: 8px;
    color: $text-grey !important;
    font-family: $primary-font-family-bold;
    font-size: 12px;
  }
  .p-tooltip-arrow {
    visibility: hidden;
  }
}

.product-tooltip {
  max-width: 350px;
}

.notification-tooltip {
  max-width: 800px;
  .p-tooltip-text {
    background-color: $primary-body-color !important;
    border: 1px solid $input-enabled-border;
    border-radius: 8px;
    color: $text-grey !important;
    font-family: $primary-font-family-bold;
    font-size: 12px;
    white-space: pre-wrap;
  }
  .p-tooltip-arrow {
    visibility: hidden;
  }
}

.quantity-tooltip {
  max-width: 180px;
}

.inventory-category-tooltip {
  max-width: 250px;
  .p-tooltip-text {
    background-color: $primary-body-color !important;
    border: 1px solid $input-enabled-border;
    border-radius: 8px;
    color: $text-grey !important;
    font-family: $primary-font-family-regular;
    font-size: 12px;
    b {
      font-family: $primary-font-family-bold;
      color: $primary-text-color !important;
    }
  }
  .p-tooltip-arrow {
    visibility: hidden;
  }
}

.tooltip-digitaltwin {
  position: absolute;
  text-align: left;
  padding: 2px;
  font-size: 12px;
  background: $primary-body-color;
  font-family: $primary-font-family-regular;
  border: 1px solid $input-enabled-border;
  border-radius: 8px;
  pointer-events: none;
  dl {
    padding: 0.5em;
  }
  dt {
    float: left;
    clear: left;
    text-align: right;
    font-family: $primary-font-family-bold;
    color: $text-grey;
  }
  dt::after {
    content: ":";
  }
  dd {
    margin: 0 0 0 115px;
    padding: 0;
  }
}

.tooltip-line-chart {
  position: absolute;
  text-align: left;
  padding: 6px;
  font-size: 12px;
  background: $primary-body-color;
  font-family: $primary-font-family-regular;
  border: 1px solid $input-enabled-border;
  border-radius: 8px;
  pointer-events: none;
  color: $text-grey;
  p {
    padding: 0;
    margin: 0;
    line-height: 1.4em;
  }
}

.tooltip-chart,
.tooltip-chart-line {
  position: absolute;
  text-align: left;
  padding: 2px;
  background: $primary-body-color;
  border: 1px solid $input-enabled-border;
  border-radius: 8px;
  pointer-events: none;
  .graph-table,
  .graph-table-line {
    margin: 0.5em;
    tr td {
      background-color: #ffffff !important;
      padding: 1px !important;
      font-family: $primary-font-family-regular;
      font-size: 12px !important;
    }
  }
  .title {
    font-family: $primary-font-family-bold !important;
    color: $text-grey;
  }
  .data-value {
    text-align: right;
  }
}

dl {
  .tooltip-data-value {
    text-align: right;
  }
}

// chnage-password
label.mat-form-field-label {
  font-size: 16px;
}

// chnage-password
// add Hosptial Search
.content {
  .search-bar {
    height: 72px;
    .input-group {
      padding: 0em;
      border: 0px;
      .addNewHospitalAutocomplete {
        border: 0;
        padding: 0.5em 0.75rem 0.5em 0 !important;
        input[type="search"]::-webkit-search-cancel-button {
          -webkit-appearance: searchfield-cancel-button;
          background-image: url("/assets/img/close-24px.svg");
          background-image: url("/assets/img/close-24px.svg");
          background-size: 100% 100%;
          position: absolute;
          display: block;
          cursor: pointer;
          font-size: 2em;
          right: 32px;
          height: 24px;
          width: 24px;
        }
        @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
          input[type="search"]::-webkit-search-cancel-button {
            -webkit-appearance: none;
          }
        }
        .history .history.material-icons {
          display: inline-block;
          vertical-align: middle;
          color: #e02d00;
          padding-left: 0.1em;
        }
        .material-icons.history + div {
          font-size: 14px;
          margin: 5px 10px 5px 0;
          padding-left: 0.5em;
          color: red;
          font-family: $primary-font-family-semibold !important;
        }
        .history .history.material-icons + div {
          display: inline-block;
          color: #e02d00;
          padding-left: 0px;
          line-height: 18px;
          font-family: $primary-font-family-semibold !important;
        }
        .p-inputtext.p-component {
          border-bottom: 0px;
          text-indent: 75px;
          font-size: 18px;
          padding: 0em 0.5em;
          line-height: 5.1em;
        }
        .p-corner-all {
          border-radius: 0px;
        }
        .p-helper-clearfix.ng-star-inserted {
          padding: 11px;
        }
        .p-autocomplete {
          width: 100%;
          // text-indent: 5px;
          .p-autocomplete-panel {
            margin-top: 10px;
          }
          &:after {
            font-family: $icon-font;
            content: "search";
            -webkit-font-feature-settings: "liga" 1;
            -moz-font-feature-settings: "liga" 1;
            font-feature-settings: "liga" 1;
            left: 0.4em;
            display: block;
            cursor: text;
            top: 10px;
            font-size: 52px;
            margin-top: -52px;
            color: $text-grey !important;
          }
          .p-inputtext.p-component {
            text-indent: 56px;
            line-height: 22px;
            font-size: 18px;
            border-bottom: 0px;
            padding: 27px 0em 0.5em;
          }
          .p-helper-clearfix.ng-star-inserted {
            padding: 11px;
            border-bottom: 1px solid $primary-border-color;
          }
          .p-autocomplete-panel {
            border: 0px;
            box-shadow: none;
            margin-top: 20px;
            right: -26px;
            font-family: $primary-font-family-book;
            z-index: 999;
            .p-autocomplete-items {
              .p-autocomplete-list-item {
                padding: 1em 2.6em;
                color: $primary-font-family-medium;
                height: 48px;
                border: 1px solid #f4f4f4;
                &.p-highlight {
                  color: $primary-font-family-medium;
                }
              }
              .p-autocomplete-loader {
                right: 0.5em;
              }
              .p-autocomplete-panel {
                border: 0px;
                box-shadow: none;
                margin-top: 2px;
                right: 0px;
                font-family: $primary-font-family-book;
              }
            }
          }
        }
      }
    }
  }
}

.extraSuturePopup {
  .p-dialog-content {
    padding: 50px 35px 35px 35px;
    border: none;
  }
  .p-dialog-header {
    background-color: #fff;
    display: none !important;
  }
}

.undo-btn {
  color: #212121;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 0px;
  padding: 12px 57px;
}

.mat-datepicker-popup {
  .mat-datepicker-content .mat-calendar {
    width: auto !important;
    height: auto !important;
  }
  .mat-calendar-table-header-divider {
    padding: 1px !important;
    border: 0px !important;
    &:after {
      left: 0px;
      right: 0px;
      height: 2px;
      top: -3px;
      background: #f4f4f4;
    }
  }
  .mat-calendar-body-label {
    opacity: 0;
    padding: 0px !important;
  }
  table tr td {
    background: #fff !important;
  }
  table tr > th {
    padding: 13px 21px 18px 16px !important;
    height: 0 !important;
    border-bottom: 0px solid transparent !important;
  }
  table tr:nth-child(odd) td {
    background-color: #fff !important;
  }
}

// .inventory-levels-list .inventory-list-con .p-datatable {
//     //min-height: 400px;
// }

/* assessment tool styles starts here */

/* .sutUtil{
input.ng-touched.ng-invalid,
    .custom-p-dropdown.ng-touched.ng-invalid > .p-dropdown > .p-dropdown-label-container > .p-dropdown-label {
      border: none;
      border-bottom: 3px solid $jnj-red;
      border-radius: 0px;
    }padding-left: 0px;
  } */

/* assessment tool styles ends here */

body
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item.p-highlight {
  font-family: $primary-font-family-black;
  color: $text-grey;
  background-color: #ffffff;
}

.productIssuePopup {
  .p-dropdown-label.p-inputtext {
    font-size: 18px;
    color: $primary-text-color;
    padding: 4px;
  }
  .p-dialog-header {
    display: none;
  }
  .p-dialog-content {
    padding: 50px 0px 20px;
  }
}

.extraConfirmationPopup {
  .p-dialog-header {
    display: none;
  }
  .p-dialog .p-dialog-content {
    border: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.scannerPopup {
  .p-dialog-titlebar {
    display: none;
  }
  .mat-mdc-form-field-infix {
    border-bottom-width: 1px;
    margin-top: 20px;
    height: 60px;
  }
  .p-dialog .p-dialog-content {
    border: none !important;
    padding: 0;
    // padding-right: 0 !important;
  }
  input::placeholder {
    text-align: center;
  }
}

input::-webkit-input-placeholder {
  color: $placeholder-text !important;
}

.recallSelectUnitContainer {
  ::-webkit-scrollbar {
    height: 6px;
  }
}

.loading-text {
  display: block;
  background-color: #f1f1f1;
  min-height: 19px;
  animation: pulse 1s infinite ease-in-out;
  text-indent: -99999px;
  overflow: hidden;
}

// asssessment - tool details
.assessment-details {
  // .p-inputtext.ng-dirty.ng-invalid,
  //   p-dropdown.ng-dirty.ng-invalid > .p-dropdown,
  //   p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext,
  //   p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext,
  //   p-chips.ng-dirty.ng-invalid > .p-inputtext,
  //   p-inputmask.ng-dirty.ng-invalid > .p-inputtext,
  //   p-checkbox.ng-dirty.ng-invalid .p-chkbox-box,
  //   p-radiobutton.ng-dirty.ng-invalid .p-radiobutton-box,
  //   p-inputswitch.ng-dirty.ng-invalid .p-inputswitch,
  //   p-listbox.ng-dirty.ng-invalid .p-inputtext,
  //    p-multiselect.ng-dirty.ng-invalid > .p-multiselect,
  //    p-spinner.ng-dirty.ng-invalid > .p-inputtext,
  //    p-selectbutton.ng-dirty.ng-invalid .p-button,
  //    p-togglebutton.ng-dirty.ng-invalid .p-button{
  // }
  button.p-autocomplete-dropdown.p-button.p-component.p-state-default.p-corner-all.p-button-icon-only.ng-star-inserted {
    color: $text-grey;
    padding: 0px;
    position: absolute;
    right: 0px;
    background: $primary-body-color;
    border: 0px;
    border-bottom: 2px solid $input-enabled-border;
    border-radius: 0px;
  }
  .hospital-name .mat-form-field-appearence-fill .mat-form-field-label {
    color: $disabled-text;
  }
  .p-inputtext.ng-dirty.ng-invalid,
  .p-dropdown.ng-dirty.ng-invalid > .p-dropdown,
  .p-autocomplete.ng-touched.ng-invalid > .p-autocomplete > .p-inputtext,
  .p-autocomplete.ng-touched.ng-invalid
    > .p-autocomplete
    > button.p-autocomplete-dropdown {
    border: 0px;
    border-bottom: 2px solid $jnj-red !important;
    color: $jnj-red;
  }
  .p-autocomplete.ng-touched.ng-invalid {
    border-bottom: 2px solid $jnj-red !important;
    color: $jnj-red;
  }
  .p-inputtext:enabled:focus:not(.p-state-error) {
    border-color: $input-enabled-border !important;
  }
  .mat-form-field-type-mat-native-select.mat-form-field-disabled
    .mat-form-field-infix::after,
  .mat-mdc-input-element:disabled {
    color: $placeholder-text;
  }
  .hospital-name .mat-mdc-form-field-infix {
    border-bottom: 2px solid $input-enabled-border;
  }
  .mat-radio-button {
    font-family: $primary-font-family-regular;
  }
  .p-dropdown .p-dropdown-label {
    padding-left: 0px !important;
    font-size: 18px !important;
    color: $primary-text-color;
  }
  .mat-mdc-input-element,
  .mat-mdc-form-field {
    line-height: 10px;
  }
  .p-dropdown.p-component,
  .p-inputtext.p-component {
    font-size: 18px !important;
  }
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $jnj-red;
    border-width: 10px;
  }
  .mat-radio-button.mat-accent .mat-radio-inner-circle,
  .mat-radio-button.mat-accent
    .mat-radio-ripple
    .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: $jnj-red;
  }
  span.mat-placeholder-required.mat-form-field-required-marker {
    color: $jnj-red;
  }
  input.p-inputtext.p-corner-all.p-component::placeholder {
    opacity: 1 !important;
    color: $primary-text-color !important;
  }
  .inval-dropdwn .p-dropdown.p-component,
  .inval-dropdwn .p-inputtext.p-component,
  .inval-dropdwn
    .p-dropdown.p-component.p-state-default.p-corner-all.p-helper-clearfix {
    border-bottom-color: $jnj-red;
    color: $jnj-red;
  }
  .inval-dropdwn
    .p-dropdown.p-component.p-state-default.p-corner-all.p-helper-clearfix
    .p-dropdown-label-container
    label {
    color: $jnj-red;
  }
  mat-radio-group.example-radio-group.mat-radio-group.ng-pristine.ng-invalid.ng-touched {
    color: $jnj-red;
  }
  .mat-form-field-invalid.mat-form-field-dirty .mat-mdc-form-field-infix {
    border-bottom: 3px solid $jnj-red !important;
  }
  .form-control:disabled,
  .form-control[readonly] {
    background-color: #ffffff !important;
    // opacity: 1;
  }
  // .number-ticker {
  //   @at-root input {
  //     background-color: red;
  //   }
  // }
  //  .number-ticker:has(> input.form-control.ng-pristine.ng-valid.ng-touched) {
  // }
}

/* multi se;ect style starts */

/* .mat-pseudo-checkbox-checked::after{
  color: $jnj-red !important;
  }
  .mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate{
    background: $jnj-red !important;
  }
  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled){
    color: $text-grey !important;
  } */

/* .mat-option{
    font-size: 18px !important;
    font-family: $primary-font-family-black !important;    
    border-bottom: 1px solid $light-grey !important;
  } */

.multiselect-item-checkbox input[type="checkbox"] + div:before {
  background-color: $primary-body-color !important;
}

/*  .mat-option.mat-active{
    background-color: $primary-body-color !important;
  } */

/*  multi se;ect style  ends */

// Sort icon related global change
.equipment-status-list,
.order-management-list,
.case-status-comp {
  table tr > th.p-sortable-column .sort-wrapper .p-sortable-column-icon {
    // right: auto;
  }
}

.customDialog.confirmationDialog.actions-error {
  max-height: 260px;
  height: 260px;
}

.customDialog.confirmationDialog {
  .footerNormal {
    display: inline !important;
  }
  .footerResp {
    display: none !important;
  }
  .cancel {
    color: $primary-text-color;
    font-family: $primary-font-family-semibold;
  }
}

.customDialogReconcile {
  .button-primary-small.responsive {
    display: none !important;
  }
  .button-primary-small.normal {
    display: inline !important;
  }
}

.customDialogReplishmentActivate {
  .button-primary-small.responsive {
    display: none !important;
  }
  .button-primary-small.normal {
    display: inline !important;
  }
}

.deleteDialog {
  .dialog-body-content {
    height: 300px;
  }
  .controlButton {
    display: block;
    margin-bottom: 0px;
    position: absolute;
    padding-left: 300px;
    bottom: 40px;
    .cancel {
      margin-right: 40px;
    }
  }
  .footer button {
    display: none;
  }
  .f48 {
    font-size: 28px;
  }
}

body {
  .staff-permission {
    /*input place holder*/
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $placeholder-text !important;
      font-family: $primary-font-family-regular;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: $placeholder-text !important;
      font-family: $primary-font-family-regular;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: $placeholder-text !important;
      font-family: $primary-font-family-regular;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: $placeholder-text !important;
      font-family: $primary-font-family-regular;
    }
  }
}

.scanner {
  .mat-expansion-panel-header {
    background: #eef4f8 !important;
  }
  .mat-expansion-panel:not([class*="mat-elevation-z"]) {
    box-shadow: none !important;
  }
  .mat-expansion-indicator::after {
    display: none;
  }
  .mat-expansion-panel-header {
    padding: 0 12px !important;
  }
  .mat-expansion-panel-body {
    padding-left: 8px !important;
    padding-bottom: 16px !important;
    padding-right: 4px !important;
  }
}

.expire-product-list {
  .p-datatable-scrollable-body {
    min-height: calc(100vh - 312px) !important;
    max-height: calc(100vh - 312px) !important;
  }
}

.configure-details {
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $jnj-red !important;
    border-width: 10px !important;
  }
  mat-radio-group.configure-radio-group.mat-radio-group.ng-pristine.ng-invalid.ng-touched {
    color: $jnj-red;
  }
  .mat-radio-button.mat-accent .mat-radio-inner-circle,
  .mat-radio-button.mat-accent
    .mat-radio-ripple
    .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: $jnj-red;
  }
  .mat-radio-button {
    font-family: $primary-font-family-regular;
  }
}

.mat-progress-bar-fill::after {
  background-color: #e02d00 !important;
}

.mat-progress-bar-buffer {
  background-color: #d0cfcd !important;
}

.mat-progress-bar {
  width: 487px !important;
  height: 7px !important;
}

// .progress-bar {
//   .p-dialog.p-component-content {
//     background: #fff;
//   }
//   .p-dialog-content {
//     border: none;
//     padding-left: 46px !important;
//     padding-right: 30px !important;
//   }
// }
// .progress-bar {
//   .p-dialog-header {
//     background-color: #fff;
//     display: none !important;
//   }
// }
.file-upload-err {
  .p-dialog .p-dialog-footer {
    border: none;
    padding-right: 21px;
    padding-bottom: 26px;
  }
  .p-dialog-header {
    background-color: #fff;
    display: none !important;
  }
  .p-dialog.p-component-content {
    background: #fff;
  }
  .p-dialog-content {
    border: none;
    padding-left: 46px !important;
    padding-right: 93px !important;
  }
}

.file-upload-err-msg {
  .p-dialog .p-dialog-footer {
    border: none;
    padding-right: 21px;
    padding-bottom: 26px;
  }
  .p-dialog-header {
    background-color: #fff;
    display: none !important;
  }
  .p-dialog.p-component-content {
    background: #fff;
  }
  .p-dialog-content {
    border: none;
    padding-left: 46px !important;
    padding-right: 93px !important;
  }
}

.clinical-autocomplete-filter {
  .p-autocomplete {
    width: 100% !important;
    .p-autocomplete-multiple-container {
      padding: 0.3em !important;
    }
    .p-autocomplete-input-token input {
      width: auto !important;
      font-family: $primary-font-family-regular; //"Open Sans", "Helvetica Neue", sans-serif;
      font-size: 14px;
    }
    .p-autocomplete-token {
      width: 100% !important;
      border-radius: 1px;
    }
    .p-autocomplete-token-label {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90% !important;
    }
  }
}
.clinical-autocomplete-filter.p-inputwrapper-filled {
  width: 66.6667% !important; /* Use !important if necessary */
}

body .search-filters .p-field-dropdown .overview-search-icon {
  color: $text-grey !important;
}

/* @media query always comes last. Put all other styles before this */

@media screen and (max-width: 1440px) {
  .staff-permission {
    .list-user {
      .p-datatable .p-datatable-thead > tr > th {
        padding-left: 10px !important;
        padding-top: 32px !important;
      }
      .p-datatable .p-datatable-tbody td {
        padding-left: 10px !important;
      }
    }
  }
  .customDialog.issueDialog1 {
    width: 80vw !important;
    height: 662px !important;
  }
  .account-setting-page {
    .p-tabview {
      padding: 0px;
      padding-left: 0px;
    }
  }
  body {
    .staff-permission {
      /*input place holder*/
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-size: 14px !important;
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        font-size: 14px !important;
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        font-size: 14px !important;
      }
      :-moz-placeholder {
        /* Firefox 18- */
        font-size: 14px !important;
      }
    }
  }
}

@media (max-width: 1024px) {
  body {
    .staff-permission.case-status-comp {
      /*input place holder*/
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-size: 14px !important;
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        font-size: 14px !important;
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        font-size: 14px !important;
      }
      :-moz-placeholder {
        /* Firefox 18- */
        font-size: 14px !important;
      }
    }
    .user-tbl .p-dropdown .p-dropdown-label {
      font-size: 1.825vw !important;
    }
  }
  .card-title {
    font-size: 14px !important;
  }
  .add-unit-wrapper {
    .p-autocomplete {
      width: 100%;
    }
  }
  .staff-permission {
    input.search-input::placeholder {
      color: $placeholder-text !important;
    }
    .sub-title {
      font-size: 16px;
    }
    .access-tbl .p-inputtext {
      font-size: 1.825vw !important;
    }
    .add-user {
      .p-inputtext.p-component {
        font-size: 1.825vw !important;
        text-overflow: ellipsis;
      }
      .p-dropdown .p-dropdown-label {
        font-size: 1.825vw !important;
      }
      ::-webkit-input-placeholder {
        font-size: 1.825vw !important;
      }
      .p-dropdown label.p-dropdown-label {
        font-size: 1.825vw !important;
        text-overflow: ellipsis;
      }
    }
    .user-tbl {
      .p-dropdown.p-component {
        min-width: 1vw;
      }
      .p-dropdown label.p-dropdown-label {
        font-size: 1.825vw !important;
        text-overflow: ellipsis;
      }
      .p-inputtext.p-component {
        font-size: 1.825vw !important;
        text-overflow: ellipsis;
      }
    }
  }
  .p-tab-custom.account-setting-page {
    .p-tabview.p-tabview-nav-container .p-tabview-content .p-tabview-nav li {
      min-width: 110px;
    }
  }
  .account-setting-page {
    .p-tabview {
      padding: 0px;
      padding-left: 0px;
    }
  }
  .profile {
    .mat-mdc-input-element,
    .mat-mdc-form-field {
      font-size: 18px !important;
    }
    .mat-mdc-form-field-infix {
      font-size: 18px !important;
    }
    label.mat-form-field-label {
      font-size: 18px;
    }
    .p-radiobutton .p-radiobutton-box {
      border: 1px solid $light-grey !important;
    }
  }
  .p-tab-custom .p-tabview .p-tabview-panels .p-tabview-panel {
    margin-top: 24px;
  }
  .pr-md-0 {
    padding-right: 0px;
  }
  .sub-title {
    font-size: 18px;
  }
  .customDialog.confirmationDialog,
  .customDialogReconcile,
  .deleteDialog {
    left: 0px !important;
    opacity: 1;
    right: 0px !important;
    width: auto !important;
    top: 0px !important;
    bottom: 0px;
    height: 100vh !important;
    position: fixed !important;
    .dialog-body-content {
      height: calc(100vh - 130px) !important;
    }
    .customContent {
      height: calc(100vh - 60px) !important;
    }
    .footerNormal {
      display: none !important;
    }
    .footerResp {
      display: block !important;
      padding-top: 100px;
      padding-left: 40vw;
      width: 80vw;
    }
    .button-primary-small.responsive {
      display: block !important;
      bottom: 40px;
      position: absolute;
      right: 28vw !important;
    }
    .button-primary-small.normal {
      display: none !important;
    }
    .controlButton {
      display: block;
      margin-bottom: 0px;
      position: absolute;
      bottom: 128px;
      padding-left: 50vw;
    }
    .f48 {
      font-size: 48px;
    }
  }
  .customDialog.issueDialog1 {
    left: 0px;
    opacity: 1;
    right: 0px;
    width: 100vw !important;
    top: 0px !important;
    bottom: 0px;
    height: 100vh !important;
    .popup-edit,
    .notes-width {
      width: calc(50% - 24px) !important;
      padding-bottom: 60px !important;
      margin-right: 24px;
      padding-right: 10%;
    }
    .footer-style {
      position: fixed !important;
      left: 20px;
      right: 20px;
    }
    .p-dialog-content {
      padding: 0vw 3vw !important;
    }
    .dialogHead .page-sub-title {
      text-align: center;
      padding-bottom: 10px;
    }
    .dialogHead1 {
      padding: 0;
    }
    .dialogHead .line {
      content: "";
      height: 2px;
      width: 100%;
      position: absolute;
      background-color: #f4f4f4;
      left: 0px;
      right: 0px;
      z-index: 90;
    }
    .button-padding {
      position: fixed;
      bottom: 100px;
      right: 20px;
      padding: 0 !important;
    }
  }
  .scannerPopup {
    left: 0px;
    opacity: 1;
    right: 0px;
    width: 100vw !important;
    top: 0px !important;
    bottom: 0px;
    height: 100vh !important;
    background: #fff;
    .p-dialog-content {
      padding-left: 16px !important;
      padding-right: 16px !important;
      // height: 662px !important;
    }
  }
  .extraSuturePopup {
    .p-dialog.p-component-content {
      position: fixed !important;
      top: 0px !important;
      right: 0px !important;
      bottom: 0px !important;
      left: 0px !important;
      width: auto !important;
      background: #fff;
      // .p-dialog-content {
      //     // height: 100vh !important;
      // }
    }
    .p-dialog-content {
      padding: 50px 15px 35px 15px;
      border: none;
    }
  }
  // Product Issue popup confirmation in reacall & expiry mobile screens
  .prod-issue-confirm-popup {
    .p-dialog.p-component-content {
      position: fixed !important;
      top: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
      width: auto !important;
      background: $primary-body-color;
    }
    .p-dialog-content {
      text-align: center;
      border: none;
      height: calc(100vh - 197px);
      padding: 0 15px;
      align-items: center;
      justify-content: center;
      display: flex;
      p {
        font-family: $primary-font-family-black;
        font-size: 28px;
        color: $primary-text-color;
      }
    }
    .p-dialog-footer.p-dialog-footer {
      text-align: center;
      button.btn {
        width: 100%;
        height: 48px;
        &:first-of-type {
          background: $primary-body-color;
          color: $jnj-red;
          border: 1px $jnj-red solid;
        }
      }
    }
  }
  // accountsettings
  .instruction-text {
    font-size: 18px;
    line-height: 24px;
  }
  .mat-mdc-input-element,
  .mat-mdc-form-field {
    line-height: 10px !important;
  }
  .req-access .mat-form-field-appearence-fill .mat-mdc-form-field-infix {
    padding: 0.75em 0 !important;
  }
  .add_newhosp .mat-form-field-appearence-fill .mat-mdc-form-field-infix {
    padding: 0.75em 0 !important;
  }
  .reset-pwd .mat-form-field-appearence-fill .mat-mdc-form-field-infix {
    padding: 0.6em 0 !important;
  }
  .forgot-pwd .mat-form-field-appearence-fill .mat-mdc-form-field-infix {
    padding: 0.75em 0 !important;
  }
  body .req-access .p-inputtext.p-placeholder.p-dropdown-label {
    padding-top: 0.76em !important;
    padding-bottom: 0.76em !important;
    padding-left: 5px !important;
  }
  .issuetbl {
    max-height: calc(100vh - 440px);
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
      padding-top: 20px;
      padding-bottom: 20px;
      font-size: 16px;
    }
  }
  .multiselect-dropdown .dropdown-btn {
    width: 196px !important;
  }
  .recall .mat-mdc-form-field {
    width: 100% !important;
  }
  body {
    .staff-permission {
      /*input place holder*/
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-size: 18px !important;
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        font-size: 18px !important;
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        font-size: 18px !important;
      }
      :-moz-placeholder {
        /* Firefox 18- */
        font-size: 18px !important;
      }
    }
  }
}

body .p-autocomplete-item {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0;
  color: #333;
  background-color: transparent;
  border-radius: 0;
  cursor: pointer;
  font-weight: 400;
  text-align: left;
  font-size: 14px;
}

@media only screen and (max-width: 480px) {
  .customDialog.issueDialog1 .button-padding {
    position: absolute;
    bottom: 15px;
    right: 15px;
    padding: 0 !important;
    left: 15px;
    button {
      bottom: 0;
    }
  }
}

@media (min-width: 1025px) {
  .instruction-text {
    font-size: 16px;
    line-height: 20px;
  }
  .scannerPopup.scan2 .buttonGroup {
    position: static !important;
  }
}

@media (max-width: 768px) {
  .deleteDialog {
    .controlButton {
      bottom: 120px;
      right: 40px;
    }
  }
  .customDialog.issueDialog1 {
    .popup-edit,
    .notes-width {
      width: 49% !important;
      padding-bottom: 40px !important;
      margin-right: 0px;
      padding-right: 1vw;
    }
    .col2 {
      width: 100% !important;
    }
  }
  .customDialogReconcile {
    .button-primary-small.responsive {
      right: 40px !important;
    }
  }
  body .staff-permission .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    font-size: 1.825vw;
  }
  body .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    font-size: 1.825vw;
  }
}

@media screen and (max-width: 1024px) {
  .footer-units {
    padding-bottom: 235px !important;
  }
  .unit-edit-mode {
    .cdk-virtual-scroll-viewport {
      contain: none;
      transform: none;
      .cdk-virtual-scroll-content-wrapper {
        transform: none !important;
        contain: none;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .customDialog.units {
    width: 768px !important;
    height: 1024 !important;
  }
  .customDialog.dialog-body-content.measure-content {
    height: 773px !important;
    background-color: blue;
  }
}

@media screen and (max-width: 600px) {
  .header {
    .search {
      display: none;
    }
    .search-bar {
      display: none;
      &.showhide {
        display: block;
      }
    }
  }
  .topPadding {
    .notification-div {
      top: 76px !important;
    }
  }
  .topPadding.search-nav-visible {
    .notification-div {
      top: 148px !important;
    }
  }
  .customDialog.issueDialog1 {
    .popup-edit,
    .notes-width {
      width: 100% !important;
    }
  }
  .productIssuePopup {
    .button-secondary-large {
      border: none;
      width: 172px !important;
      position: relative;
      display: block;
      margin: 0 auto;
      bottom: -50px;
    }
    .button-primary-large {
      width: 100%;
      position: relative;
      bottom: 50px;
    }
    .button-secondary-large:hover:enabled,
    .button-secondary-medium:hover:enabled,
    .button-secondary-small:hover:enabled {
      background: #fff;
      color: $jnj-red;
    }
    .button-primary-large:hover:enabled,
    .button-primary-medium:hover:enabled,
    .button-primary-small:hover:enabled {
      background: $jnj-red;
      color: #fff !important;
    }
    .popupContainer {
      overflow-x: hidden;
      height: calc(100vh - 130px);
      overflow-y: scroll;
      padding: 0 4px;
    }
    .footer-style {
      position: absolute;
      bottom: 0 !important;
      left: 40px;
      right: 40px;
    }
  }
  body .productIssuePopup .p-dropdown-panel .p-dropdown-items .p-dropdown-item,
  .productIssuePopup
    .p-dropdown-panel
    .p-dropdown-items
    .p-dropdown-item-group {
    color: $primary-text-color !important;
  }
  .mat-datepicker-popup {
    table tr > th {
      padding: 13px 10px 18px 16px !important;
      height: 0 !important;
    }
  }
}

body .p-accordion .p-accordion-header:not(.p-disabled).p-state-active a {
  background-color: #fff;
  border: none;
  color: #000;
}

body .p-accordion .p-accordion-header:not(.p-disabled).p-state-active:hover a {
  background-color: #fff;
  border: none;
  color: #000;
}
body
  .p-accordion
  .p-accordion-header:not(.p-state-active):not(.p-disabled):hover
  a {
  background-color: #fff;
  border: none;
  color: #000;
}

body .p-accordion .p-accordion-content {
  padding: 0.571em 1em;
  border: none;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em;
}

body .p-accordion .p-accordion-header a {
  padding: 0.571em 1em;
  border: none;
  color: #333333;
  background-color: #fff;
  color: #333333;
  font-weight: 700;
  border-radius: 3px;
  transition: background-color 0.2s, box-shadow 0.2s;
}

.p-accordion .p-accordion-header-text {
  font-size: 22px;
  font-family: $primary-font-family-semibold;
}

span.p-accordion-header-text.ng-tns-c15-0.ng-star-inserted {
  font-size: 22px;
}

span.p-accordion-header-text.ng-tns-c15-1.ng-star-inserted {
  font-size: 22px;
}

span.p-accordion-header-text.ng-tns-c15-2.ng-star-inserted {
  font-size: 22px;
}
body .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
  background: none !important;
}

.overview-date-picker .mat-mdc-text-field-wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;
  background-color: transparent !important;
  border: 1px solid $secondary-text-color !important;
  padding: 0 0 0 0 !important;
  border-radius: 10px !important;
  box-shadow: 0px 2px 2px 0px $secondary-text-color;
  height: 40px;

}
.overview-date-picker .mat-mdc-form-field-infix .mat-mdc-input-element {
  font-size: 14px !important;
  height: 100% !important;
}
@media screen and (max-width: 830px) {
  .p-dropdown.p-component,
  .p-inputtext.p-component {
    min-width: 137px;
  }
  .access-tbl .p-dropdown.p-component,
  .p-inputtext.p-component {
    min-width: 105px;
  }
}

@media screen and (max-width: 950px) {
  .multiselect-dropdown .dropdown-btn {
    width: 150px !important;
  }
  body .sutUtil .p-dropdown .p-dropdown-label {
    text-overflow: ellipsis !important;
  }
}

@media screen and (max-width: 768px) {
  .multiselect-dropdown .dropdown-btn {
    width: 137px !important;
  }
  /* .field-width1 .multiselect-dropdown .dropdown-btn {
    width: 109px !important;
  } */
}

@media screen and (max-width: 766px) {
  .p-dropdown.p-component,
  .p-inputtext.p-component {
    min-width: 109px !important;
  }
}

@media screen and (max-width: 710px) {
  .p-dropdown.p-component,
  .p-inputtext.p-component {
    min-width: 70px !important;
  }
  .multiselect-dropdown .dropdown-btn {
    width: 137px !important;
  }
}

@media screen and (max-width: 1024px) {
  .file-upload-err {
    .p-dialog .p-dialog-footer {
      padding-top: 108px;
      padding-bottom: 127px !important;
    }
    .header3 {
      font-size: 48px !important;
      line-height: 54px;
    }
  }
  .file-upload-err-msg {
    .p-dialog .p-dialog-footer {
      padding-top: 108px;
      padding-bottom: 127px !important;
    }
    .header3 {
      font-size: 48px !important;
      line-height: 54px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .progress-bar {
    .header3 {
      font-size: 48px !important;
      line-height: 54px;
    }
  }
}

/* @media query always comes last. Put all other styles before this */
