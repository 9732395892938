/*FONT*/

$primary-font-family-book: "J&J Circular Book Web";
$primary-font-family-light: "Noto Sans Regular";
$primary-font-family-regular: "Noto Sans Regular";
$primary-font-family-medium: "Noto Sans Med";
$primary-font-family-black: "Noto Sans Black";
$primary-font-family-semibold: "Noto Sans SemiBold";
$primary-font-family-bold: "Noto Sans Bold";
$primary-font-size: 14px;
$icon-font: 'Material Icons';
$icon-font-outlined: "Material Icons Outlined";

/*COLORS*/

$primary-body-color: #FFFFFF;
$primary-text-color: #212121;
$secondary-text-color: #7c7c7c;
$jnj-red: #E02D00;
$jnj-dark-red: #CA001B;
$jnj-light-orange:#F56600;
$jnj-orange-text:#fe5000;
$jnj-green:#00AA55;
$jnj-blue:#1B294F;
$jnj-yellow:#FEDD00;
$text-white: #fff;
$text-grey:#63666A;
$lightest-grey:#F4F4F4;
$light-grey:#D0CFCD;
$disabled-grey:#D8D8D8;
$jnj-text-blue:#12224D;
$jnj-text-blue-light:#b1c9e8;

/*ELEMENT COLORS*/

$disabled-text: #888b8d;
$disabled-button: #D8D8D8;
$primary-border-color: #F4F4F4;
$secondary-border-color:#D0CFCD;
$grey-border:#D8D8D8;
$placeholder-text: #888b8d;
$input-enabled-border: #63666A;

/*TABLE COLORS*/

$table-row-bg-color:#5C97BF;
$table-head-text-color:#63666A;
$table-head-bg-color:#FFFFFF;
$table-head-font:#F4F4F4;
$spacer: 5;
$notifi-font-clr:#63666A;
$editIconMaterial: #231F20;

/*BreakPoints*/

$desktop-hd-mode:1440px;
$desktop-mode:1024px;
$tablet-mode:768px;
$tablet-portrait-mode:600px;
$mobile-mode:320px;